import React, { useContext } from 'react';
import { Divider, List, ListItemText } from '@mui/material';
import NavListItem from './NavListItem';
import CollapseableNavList from './CollapseableNavList';
import { AuthContext } from 'core/context';
import { isRolesPermitted } from 'core/utils/common';

const NavList = ({ isSideDrawerOpen = false, menuItems, sx = {}, onNavLinkClick, isLicensed=false }) => {

  const authContext = useContext(AuthContext);
  const { permissions = [] } = authContext;

  return (
      <List>
        {menuItems.map((menuItem, index) => {
          if (
            menuItem?.group && 
            ( !menuItem.hasOwnProperty('roles') || 
              (menuItem?.roles && Array.isArray(menuItem.roles) && isRolesPermitted(menuItem.roles, permissions))
            ) &&
            ((menuItem?.licenseRequired || false) === false || menuItem?.licenseRequired === isLicensed)
          ) {
            return <CollapseableNavList menuItem={menuItem} key={index} onNavLinkClick={onNavLinkClick} />
          } else if( menuItem?.divider){
              return <Divider key={index}  sx={{my: 0.5, mx: 1}}/>
          } else if( menuItem?.subHeading){
            return isSideDrawerOpen ? (
              <ListItemText
                key={index} 
                primary={menuItem.name}
                primaryTypographyProps={{
                  fontSize: 16,
                  fontWeight: 'medium',
                  lineHeight: '20px',
                  texttransform: 'uppercase',
                  borderBottom: '1px solid #dfdfdf',
                  pb: '.6rem',
                }}
                // secondary="Authentication, Firestore Database, Realtime Database, Storage, Hosting, Functions, and Machine Learning"
                // secondaryTypographyProps={{
                //   noWrap: true,
                //   fontSize: 12,
                //   lineHeight: '16px',
                //   color: open ? 'rgba(0,0,0,0)' : 'rgba(255,255,255,0.5)',
                // }}
                sx={{ mt: 2, pl: 2, pr: 2 }}
              />
            ) : null;
          }else if(
              menuItem.url && 
              (
                !menuItem.hasOwnProperty('roles') || (menuItem?.roles && Array.isArray(menuItem.roles) && isRolesPermitted(menuItem.roles, permissions))
              ) &&
              ((menuItem?.licenseRequired || false) === false || menuItem?.licenseRequired === isLicensed)
            ){
            return <NavListItem menuItem={menuItem} key={index} sx={sx} onNavLinkClick={onNavLinkClick} />
          }
          return null;
        })}
      </List>
  );
}
 
export default NavList;
