// import { createTheme } from '@mui/material/styles';
import M3Theme from './M3';

// let theme = createTheme({
//   // zIndex: {
//     //   drawer: 1301,
//     // },
//   palette: {
//     mode: 'light',
//     // Primary
//     primary: {
//       main: '#bd023c',
//       contrastText: '#ffffff',
//       light: '#fff3f3'
//     },
//     primaryContainer: {
//       main: '#ffdadb', 
//       contrastText: '#40000e'
//     },
//     onPrimaryContainer: {
//       main: '#40000e',
//       contrastText: '#ffdadb'
//     },
//     onPrimary: {
//       main: '#ffffff',
//       contrastText: '#bd023c'
//     },
//     // Secondary
//     // secondary: {
//     //   main:'#765658', 
//     //   contrastText: '#ffffff'
//     // },
//     // secondaryContainer: {
//     //   main: '#ffdadb',
//     //   contrastText: '#2c1517'
//     // },
//     // onSecondaryContainer: {
//     //   main: '#2c1517',
//     //   contrastText: '#ffdadb',
//     // },
//     // onSecondary: {
//     //   main: '#765658',
//     //   contrastText: ''
//     // },
//     // // secondary blue
//     // secondary: {
//     //   main:'#6750a4', 
//     //   contrastText: '#ffffff'
//     // },
//     // secondaryContainer: {
//     //   main: '#e9ddff',
//     //   contrastText: '#22005c'
//     // },
//     // onSecondaryContainer: {
//     //   main: '#22005c',
//     //   contrastText: '#e9ddff',
//     // },
//     // onSecondary: {
//     //   main: '#fff',
//     //   contrastText: '#6750a4'
//     // },
//     // secondary magenta
//     secondary: {
//       main:'#984060', 
//       contrastText: '#ffffff'
//     },
//     secondaryContainer: {
//       main: '#ffd9e2',
//       contrastText: '#3e001d'
//     },
//     onSecondaryContainer: {
//       main: '#3e001d',
//       contrastText: '#ffd9e2',
//     },
//     onSecondary: {
//       main: '#fff',
//       contrastText: '#984060'
//     },

//     // Tertiary
//     tertiary: {
//       main: '#775930', 
//       contrastText: '#fff'
//     },
//     onTertiary: {
//       main: '#ffffff',
//       contrastText: '#775930',
//     },
//     tertiaryContainer: {
//       main: '#ffddb4', 
//       contrastText: '#291800'
//     },
//     onTertiaryContainer: {
//       main: '#291800',
//       contrastText: '#ffddb4',
//     },
//     // surface
//     surface: {
//       main: '#fffbff',
//       contrastText: '#201a1a',
//     },
//     onSurface: {
//       main: '#201a1a',
//       contrastText: '#fffbff',
//     },
//     surfaceVariant: {
//       main: '#f4ddde', 
//       light: '#fceaeb',
//       contrastText: '#524344',
//     },
//     onSurfaceVariant: {
//       main :'#524344',
//       contrastText: '#f4ddde'
//     },
//     surfaceTint: {
//       main: '#bd023c',
//       // contrastText: ''
//     },
//     outline: {
//       main: '#857373',
//       contrastText: '#d7c1c2',
//     },
//     outlineVariant: {
//       main: '#d7c1c2',
//       contrastText: '#857373'
//     },
//     black: {
//       main: '#212121',
//       contrastText: '#ffffff',
//       light: '#c6c6c6'
//     },

//     // onPrimary: '#ffffff',
//     // primaryContainer: {main: '#ffdadb', contrastText: '#40000e'},
//     // onPrimaryContainer: '#40000e',
//     // // Secondary
//     // secondary: {main:'#765658', contrastText: '#ffffff'},
//     // onSecondary: '#ffffff',
//     // secondaryContainer: {main: '#ffdadb'},
//     // onSecondaryContainer: '#2c1517',
//     // // Tertiary
    
//     // onTertiary: '#ffffff',
//     // tertiaryContainer: {main: '#ffddb4', contrastText: '#291800'},
//     // onTertiaryContainer: '#291800',
//     // // Error
//     // error: {main: '#ba1a1a'},
//     // onError: '#ffffff',
//     // errorContainer: '#ffdad6',
//     // onErrorContainer: '#410002',
//     // // Error
//     // background: '#fffbff',
//     // onBackground: '#201a1a',
//     // // Surface
//     // surface: '#fffbff',
//     // onSurface: '#201a1a',
//     // surfaceVariant: {main: '#f4ddde', light: '#fceaeb'},
//     // onSurfaceVariant: '#524344',
//     // surfaceTint: '#bd023c',

//     // outline: '#857373',
//     // outlineVariant: '#d7c1c2',

//     // inversePrimary: '#ffb2b8',
//     // inverseSurface: '#362f2f',
//     // inverseOnSurface: '#fbeeed',
    
//     // shadow: '#000000',
//     // scrim: '#000000',
    
//     // // primary: {
//     // //   light: '#bc003b',
//     // //   // lighter: '#eaf0f0', // dce5f4
//     // //   // lighter1: '#dce2e2',
//     // //   // // main: '#3E4A5C',
//     // //   // main: '#0e1d34',
//     // //   // dark: '#3E4A5C',
//     // //   // // darker: '#091424',
//     // //   // contrastText: '#ffffff',
//     // //   // dullBlack: "#555555"
//     // // },
//     // // secondary: {
//     // //   light: '#ee6350',
//     // //   lighter: '#fdafa4',//ffeec5 
//     // //   main: '#C74634',
//     // //   dark: '#b81700',//ffac00
//     // //   darker: '#8a1100',//c78e00
//     // //   contrastText: '#ffffff',
//     // // },
//     // // // secondary: {
//     // // //   light: '#F0CC71',
//     // // //   lighter: '#eed598',//ffeec5 
//     // // //   main: '#cb9b23',
//     // // //   dark: '#eeaa00',//ffac00
//     // // //   darker: '#966c03',//c78e00
//     // // //   contrastText: '#ffffff',
//     // // // },
//     // // // secondary: {
//     // // //   light: '#ED4B82',
//     // // //   lighter: '#F8FF07',
//     // // //   main: '#fd4a87',
//     // // //   dark: '#e91e63',
//     // // //   darker: '#F8B507',
//     // // //   contrastText: '#ffffff',
//     // // // },
//     // black: {
//     //   main: '#212121',
//     //   contrastText: '#ffffff',
//     //   light: '#c6c6c6'
//     // },
//     // white: {
//     //   main: '#ffffff',
//     //   contrastText: '#212121',
//     // },
//     // typography: {
//     //   main: '#212121',
//     //   heading: '#3a3541de',
//     //   light: '#555555',
//     //   lighter: '#838282',
//     //   dark: '#BDBDBD',
//     //   contrastText: '#  ',
//     // }
//   },
//   shape: {
//     borderRadius: 10
//   },
//   components: {
//     MuiButton: {
//       variants: [
//         {
//           props: { variant: 'bt-btn-light' },
//           style: {
//             textTransform: 'none',
//           }
//         },
//       ],
//       styleOverrides: {
//         root: {
//           textTransform: 'none',
//           boxShadow: 'none',
//           borderRadius: 24,
//           '&:hover': {
//             boxShadow: 'none'
//           },
//           // '& label': {
//           //   color: '#f7b405',
//           // },
//           // '& label.Mui-focused': {
//           //   color: '#f7b405',
//           // },
//           // '& .MuiInput-underline:after': {
//           //   borderBottomColor: '#f7b405',
//           // },
//           // '& .MuiOutlinedInput-root': {
//           //   '& fieldset': {
//           //     borderColor: '#f7b405',
//           //   },
//           //   '&:hover fieldset': {
//           //     borderColor: '#f7b405',
//           //   },
//           //   '&.Mui-focused fieldset': {
//           //     borderColor: '#f7b405',
//           //   },
//           // },
//         },
//       }
//     },
//     MuiTab: {
//       styleOverrides: {
//         root: {
//           textTransform: 'none',
//         }
//       }
//     }
//   },
//   // shadows:{
//   //   25: "rgb(61 71 82 / 20%) 0px 4px 20px",
//   //   26: "rgb(0 0 0 / 10%) 0px 20px 25px, rgb(0 0 0 / 4%) 0px 10px 10px"
//   // }
//   // components: {
//   //   MuiTextField: {
//   //     styleOverrides: {
//   //       root: {
//   //         '& label': {
//   //           color: '#f7b405',
//   //         },
//   //         '& label.Mui-focused': {
//   //           color: '#f7b405',
//   //         },
//   //         '& .MuiInput-underline:after': {
//   //           borderBottomColor: '#f7b405',
//   //         },
//   //         '& .MuiOutlinedInput-root': {
//   //           '& fieldset': {
//   //             borderColor: '#f7b405',
//   //           },
//   //           '&:hover fieldset': {
//   //             borderColor: '#f7b405',
//   //           },
//   //           '&.Mui-focused fieldset': {
//   //             borderColor: '#f7b405',
//   //           },
//   //         },
//   //       },
//   //     }
//   //   }
//   // }
// });

export default M3Theme;
