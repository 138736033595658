import axios from 'axios';
import qs from 'qs';
import authService from 'core/services/authService';
import { ROUTES } from 'routes/constants';
import { businessDetails  } from 'core/services/businessService';
// import { DeleteUserInfoInBrowser } from 'core/services/auth';

class RequestHandler {
  #axiosInstance;
  #authExecudedRequestsArr;
  #signInUri = ROUTES.LOGIN;
  #baseURL = `${process.env.REACT_APP_API_ORIGIN ? process.env.REACT_APP_API_ORIGIN : window.location.origin}${process.env.REACT_APP_API_PATH}`;

  constructor(requestsArr) {
    this.#authExecudedRequestsArr = requestsArr;
    this.createAxiosInstance();
  }

  // Axois config defaults 
  getInstanceConfig(requestConfig = {}, signal){
    const defaultConfig = {
      baseURL: this.#baseURL
    }
    return {
      ...defaultConfig,
      ...requestConfig,
      ...(signal ? {signal}: {}),
    };
  }

  #showProgress(pageRef) {
    if(pageRef?.current){
      pageRef.current?.classList.add('loading');
    }
  }
  
  #hideProgress(pageRef) {
    if(pageRef?.current){
      setTimeout(()=>pageRef.current?.classList.remove('loading'),0);
    }
  }

  redirectToLogin() {
    authService.clearData();
    window.location.href = this.#signInUri;
  }

  // Create an instance using the config defaults provided by the library
  createAxiosInstance(){
    // Get config defaults when creating the instance
    const instanceConfig = this.getInstanceConfig();
    this.#axiosInstance = axios.create(instanceConfig);

    // Request Interceptor
    this.#axiosInstance.interceptors.request.use(
    (req) => {
      // Show Progress bar
      this.#showProgress(req.pageRef);
      return req;
    });

    // Response Interceptor
    this.#axiosInstance.interceptors.response.use(
      (res) => {

        this.#hideProgress(res.config.pageRef);
        if(res?.status === 200){
          const url = res?.config?.url
          if((url === '/authenticate' || url === '/refreshToken')&& res.headers?.authorization){
            authService.setAuthToken(res.headers.authorization.replace('Bearer ', ''));
            authService.setRefreshToken(res.headers.refreshtoken.replace('Bearer ', ''));
          }
        }

        // redirect if status code 401
        // if(res.status === 401){
        //   authService.clearData();
        //   window.location.href = this.#signInUri;
        // }
        
        return res;
      },
      (error) => {

  
        // return this.refreshAuthToken(error.config);
        if(error?.response?.config?.pageRef){
          this.#hideProgress(error.response.config.pageRef);
        }

        if(error?.response?.status === 401){
          // authService.clearData();
          // window.location.href = this.#signInUri;
          this.redirectToLogin();
        }else if(error?.response?.request?.responseURL?.includes('/pg/landing') && error?.response?.status !== 200) {
          if(error?.response?.request?.responseURL?.includes('/pg/landing') && error?.response?.status === 500){
            const userId = window.sessionStorage.getItem('_pgm$user');
            businessDetails.deleteBusinessIdInBrowser(userId);
          }

          this.redirectToLogin();
        // Do something with response error
        }else if(error?.response?.status === 406) {
          // const reqUrl = error?.response?.request?.responseURL;
          // authService.clearData();
          // window.location.href = this.#signInUri;
          // if(!(reqUrl.includes('/pg/landing') || reqUrl.includes('/refreshToken') || reqUrl.includes('/authenticate'))){
          //   this.refreshAuthToken(error.config).then((response) => {
          //     return Promise.resolve(response);
          //   });
          // }else{
          //   this.redirectToLogin();
          // }
          this.redirectToLogin();
        }
        // Trow errr again (may be need for some other catch)
        return Promise.reject(error?.response?.data || error);
    }
    );
  }

   refreshAuthToken(config, callback) {
    return this.GET({url: '/refreshToken'})
    .then(({response, error}) => {
      if(error){
        this.redirectToLogin();
        return;
      }
      return this.sendRequest({axiosRequestConfig: this.addAccessToken(config, false)}).then(({response, error}) => {
        if(error) return this.redirectToLogin();
        return Promise.resolve(response);
      })

    });
  }

  isPathExcludedFromAuthentication(url) {
    let isExcluded = false;

    for (let i = 0; i < this.#authExecudedRequestsArr.length; i++) {
      const excludePath = this.#authExecudedRequestsArr[i];
      if (url.includes(excludePath)) {
        isExcluded = true;
        break; 
      }
    }
    return isExcluded;
  }

  addAccessToken(config={}, ignore=true){
    if(this.isPathExcludedFromAuthentication(config?.url)){
      return {...config};
    }

    const authToken = authService.getAuthorizationToken();
    const refreshToken = authService.getRefreshToken();

    if(authToken && authToken !== 'null'){
      // const expiredAuthToken='eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiIwOWU1OWRmNy05M2E4LTRhMmQtOTJmMC02NDdkMDdiNDUzYzgiLCJleHAiOjE2OTE3NzkxNjUsImlhdCI6MTY5MTc3MTk2NX0.EboVieu17iZP_-GDiiezfpS9g1rANYgCv3bCa2jzBHDpV70dtBdATI6HFCgUn9TDzKekWNLSP9TKTtVIIX4z5Q';
      // if(ignore && authToken === 'eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJlNWZmOGVkMy00NGE3LTExZWUtYTRmNC1jNDM3NzIzNGZjYzIiLCJleHAiOjE3MDc0MTYwMTAsImlhdCI6MTcwNzQwODgxMH0.0MRey_XhAdSj2zPN7n-VCkr5rS9OObmCoTs0r-GjIBHpnoyQ5LPZUaP7YC2jC7ljxYYfQfK9rGfRXF9Jk3pDgA')
      //   return { ...config, headers: { ...(config.headers || {}),  'Authorization': `Bearer ${expiredAuthToken}` }  }
      // else
      const refreshTokenHeader = config?.url?.includes('/refreshToken') ? {'refreshtoken':  refreshToken } : {};
        return { ...config, headers: { ...(config.headers || {}),  'Authorization': `Bearer ${authToken}`, ...refreshTokenHeader }  }
    }
    return config;
  }

  mapBusinessId(url) {
    return businessDetails?.businessId ? url.replace(':businessId:', businessDetails?.businessId) : url;
  }

  async sendRequest({url, method='GET', params={}, body={}, reqConfigs={}, axiosRequestConfig=null}, options={}){
    try{
      let requestConfig;
      if(axiosRequestConfig){
        requestConfig = axiosRequestConfig;
      }else{
        const {signal, backgroundRequest=false, pageRef} = options;
        if(!url) return;

        const urlWithBusinessId = this.mapBusinessId(url);

        let config = {
          url: urlWithBusinessId,
          method,
          ...reqConfigs,
          isBackgroundRequest: !!pageRef || backgroundRequest,
          pageRef,
        };

        config = this.addAccessToken(config);

        // assign query params if available
        if(Object.keys(params).length > 0) {
          config.params = { ...params };
          config.paramsSerializer = function (params) {
            return qs.stringify(params, {arrayFormat: 'brackets'})
          };
        }

        // assign body data    
        if(method === 'POST' || method === 'PUT' || method === 'PATCH') {
          config.data = body;
        } 
        // Get configs for request
        requestConfig = this.getInstanceConfig(config, signal);
      }

      const response = await this.#axiosInstance(requestConfig);
      return {
        statusCode: response.status,
        response: response.data,
        error: undefined,
      };
    }catch(e){
      return {
        statusCode: e.statusCode || e.status,
        response: undefined,
        error: e,
        message: e?.message
      };
    }
  }

  async GET({url, params={}, reqConfigs={}}, options={}) {
    return this.sendRequest({url, method: 'GET', params, reqConfigs}, options); 
  }

  async PUT({url, body={}, params={}, reqConfigs={}}, options={}) {
    return this.sendRequest({url, method: 'PUT', params, body, reqConfigs}, options); 
  }

  async POST({url, body={}, params={}, reqConfigs={}}, options={}) {
    return this.sendRequest({url, method: 'POST', params, body, reqConfigs}, options); 
  }
  
  async PATCH({url, body={}, params={}, reqConfigs={}}, options={}) {
    return this.sendRequest({url, method: 'PATCH', params, body, reqConfigs}, options); 
  }
  
  async DELETE({url, params={}, reqConfigs={}}, options={}) {
    return this.sendRequest({url, method: 'DELETE', params, reqConfigs}, options); 
  }
  
  async FILE({url, params={}, configs={}}, options={}) {
    const fileConfigs = { headers: {'accept': "application/octet-stream"}, responseType: "blob" };
    return this.sendRequest({url, method: 'GET', params, reqConfigs: {...fileConfigs, ...configs}}, options); 
  }
  
  async FILEUPLOAD({url, params={}, body={},configs={}}, options={}) {
    const fileConfigs = { headers: {'Content-Type': "multipart/form-data; charset=utf-8; boundary=" + Math.random().toString().substr(2)} };
    const formBody = this.getFormData(body);
    return this.sendRequest({url, method: 'POST', params, body: formBody, reqConfigs: {...fileConfigs, ...configs}}, options); 
  }

  async URL({url, params={}}) {
    const finalUrl = `${this.#baseURL}${this.mapBusinessId(url)}`;
    window.open(finalUrl, '_blank');
  }

  getFormData(object) {
    const formData = new FormData();
    Object.keys(object).forEach(key => formData.append(key, object[key]));
    return formData;
  }


  getImageContent({url, params={}, body={},configs={}}, options={}) {
    const fileConfigs = { 
      // headers: {'accept': "image/jpeg"}, 
      responseType: "blob" };
    return this.sendRequest({url, method: 'GET', params, reqConfigs: {...fileConfigs, ...configs}}, options)
  }

  getAxiosInstance() { return this.#axiosInstance }
}

const authExecudedRequest=['/authenticate', 'sendPasswordResetOtp'];

const httpService = new RequestHandler(authExecudedRequest);
const axiosInstance = httpService.getAxiosInstance();

export { httpService, axiosInstance };
