import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { ThemeProvider } from '@mui/material/styles';
import pgmTheme from 'core/theme';
// import { grey } from '@mui/material/colors';
// import Zoom from '@mui/material/Zoom';

// const Transition = React.forwardRef(function Transition(props, ref) {
//   return <Zoom direction="up" ref={ref} {...props} unmountOnExit/>;
// });

const ConfirmationDialog = ({ open, options, onCancel, onConfirm, onClose }) => {
  const {
    title,
    description,
    content,
    confirmationText="Confirm",
    cancellationText = "Cancel",
    dialogProps,
    confirmationButtonProps,
    cancellationButtonProps,
    titleProps,
    contentProps,
    allowClose,
    maxWidth,
    disableActions,
    component
  } = options;

  return (
    <ThemeProvider theme={pgmTheme}>
      <Dialog maxWidth={maxWidth || "xs"} fullWidth {...dialogProps} open={open} onClose={allowClose ? onClose : null} >
        {title && (
          <DialogTitle {...titleProps} fontSize={17} fontWeight={400}>{title}</DialogTitle>
        )}
        {content ? (
          <DialogContent {...contentProps}>
            {content}
          </DialogContent>
        ) : (
          description && (
            <DialogContent {...contentProps}>
              <DialogContentText >{description}</DialogContentText>
            </DialogContent>
          )
        )}
        {component && <DialogContent {...contentProps}>{React.cloneElement(component, {onConfirm})}</DialogContent>}
        {/* component && <options.component onConfirm={onConfirm} /> */}
        {disableActions ? null :(
          <DialogActions>
            <Button  {...cancellationButtonProps}  onClick={onCancel} >
              {cancellationText}
            </Button>
            <Button  {...confirmationButtonProps} color='success' onClick={onConfirm}>
              {confirmationText}
            </Button>
          </DialogActions>
        )}
      </Dialog>
    </ThemeProvider>
  );
};

export default ConfirmationDialog;
