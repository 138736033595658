export const PrimaryDefault = {
    "0": "#000000",
    "10": "#40000e",
    "20": "#67001d",
    "25": "#7c0024",
    "30": "#91002c",
    "35": "#a70034",
    // "40": "#bd033c",
    "40": "#e40047",
    "50": "#e12c53",
    "60": "#ff506d",
    "70": "#ff8793",
    "80": "#ffb2b8",
    "90": "#ffdadb",
    "95": "#ffeced",
    "98": "#fff8f7",
    "99": "#fffbff",
    "100": "#ffffff"
};

export const SecondaryDefault = {
    "0": "000000",
    "10": "#3e001d",
    "20": "#5e1132",
    "25": "#6c1d3d",
    "30": "#7b2949",
    "35": "#8a3454",
    "40": "#984060",
    "50": "#b75879",
    "60": "#d57193",
    "70": "#f48bad",
    "80": "#ffb1c7",
    "90": "#ffd9e2",
    "95": "#ffecef",
    "98": "#fff8f8",
    "99": "#fffbff",
    "100": "#ffffff"
};

export const TertiaryDefault = {
    "0": "000000",
    "10": "#291800",
    "20": "#432c06",
    "25": "#503610",
    "30": "#5d421b",
    "35": "#6a4d25",
    "40": "#775930",
    "50": "#927146",
    "60": "#ae8b5d",
    "70": "#caa575",
    "80": "#e8c08e",
    "90": "#ffddb4",
    "95": "#ffeedd",
    "98": "#fff8f4",
    "99": "#fffbff",
    "100": "#ffffff"
};

export const NeutralDefault = {
    "0": "000000",
    "10": "#201a1a",
    "20": "#362f2f",
    "25": "#413a3a",
    "30": "#4d4545",
    "35": "#595051",
    "40": "#655c5c",
    "50": "#7e7575",
    "60": "#998e8e",
    "70": "#b4a9a9",
    "80": "#d0c4c4",
    "90": "#ece0df",
    "95": "#fbeeee",
    "98": "#fff8f7",
    "99": "#fffbff",
    "100": "#ffffff"
};
export const NeutralVariantDefault = {
    "0": "000000",
    "10": "#241819",
    "20": "#3b2d2e",
    "25": "#463839",
    "30": "#524344",
    "35": "#5e4f4f",
    "40": "#6b5a5b",
    "50": "#857373",
    "60": "#9f8c8d",
    "70": "#bba6a7",
    "80": "#d7c1c2",
    "90": "#f4ddde",
    "95": "#ffeced",
    "98": "#fff8f7",
    "99": "#fffbff",
    "100": "#ffffff"
};

export const ErrorDefault = {
    "0": "000000",
    "10": "#410002",
    "20": "#690005",
    "25": "#7e0007",
    "30": "#93000a",
    "35": "#a80710",
    "40": "#ba1a1a",
    "50": "#de3730",
    "60": "#ff5449",
    "70": "#ff897d",
    "80": "#ffb4ab",
    "90": "#ffdad6",
    "95": "#ffedea",
    "98": "#fff8f7",
    "99": "#fffbff",
    "100": "#ffffff"
};