import React, { useCallback, useContext, useEffect } from "react"
import { parseJwt } from 'core/utils/common';
import { AuthContext } from "core/context";
import { getNewAccessToken } from "core/services/auth";
import authService from "core/services/authService";

const TokenRefresh = () => {

  const { isUserLoggedIn, authToken: accessToken, updateAuthContext } = useContext(AuthContext);

  const fetchNewAccessToken = useCallback(() => {
    const controller = new AbortController();
    getNewAccessToken({signal: controller.signal}).then(({error}) => {
      // if(statusCode){
      //   updateAuthContext({isUserLoggedIn: true, ...data});
      //   return;
      // }
      if(!error){
        const newToken = authService.getAuthorizationToken();
        updateAuthContext({isUserLoggedIn: true, authToken: newToken});
      }
    });
    return controller;
  },[updateAuthContext]);
  

  useEffect(() => {
    let interval, controller;

    if(isUserLoggedIn){
      const decodedJWT = parseJwt(accessToken);
      const { iat, exp } = decodedJWT || {};
      // Proceed to get expiry time diff
      if(iat && exp){
        // 120 - reduced number of sec to get token beofre expiry
        const tokenExpiresInSec = ((exp * 1000) - new Date().getTime()) - (1000 * 120);
        if(tokenExpiresInSec > 0) {
          // Get new Access Token every interval seconds in background
          interval = setInterval(() => {
            controller = fetchNewAccessToken();
          }, (tokenExpiresInSec));
        }else{
          // controller = fetchNewAccessToken();
        }
      }
    }
  
    return () => {
      if(controller?.abort)controller.abort();
      clearInterval(interval);
    }
  }, [isUserLoggedIn, accessToken, fetchNewAccessToken ])

  return null;
}
 
export default React.memo(TokenRefresh);
