import React, { useEffect, useRef } from 'react';
import { Paper, Grid, Stack } from '@mui/material';
import { Box } from '@mui/system';
// import { useTheme } from '@mui/material/styles';
// import AppBreadcrumbs from 'core/components/breadcrumb/';
import { ApiProgress } from 'core/components/Loader';
import { logger } from 'core/utils/logger';

const BlankPage = React.memo(({title, subTitle, pageData, children, breadcrumbs}) => {
  logger.info('BlankPage layout mounting...');
  
  // const { palette } = useTheme();
  const pageRef = useRef(null);

  useEffect(() => {
    logger.debug('BlankPage layout mounted');
  
    return () => {
    }
  }, []);
  

  return (
    <Grid container>
      {/* <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
        <AppBreadcrumbs breadcrumbs={breadcrumbs}/>
      </Grid>   */}
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
        <Paper elevation={0} sx={{ minHeight: '80vh', mt: 2, minWidth: '244px' }} ref={pageRef} className='app-page-container'>
          <Box className='loading-wrapper'></Box>
          <Stack direction="row" justifyContent="space-between" >
          </Stack>
          <Box sx={{height: '4px'}}>
            <Box className='api-progress-container'>
                <ApiProgress styles={{borderTopLeftRadius: '10px', borderTopRightRadius: '10px', marginTop: '1px'}}/>
            </Box>
          </Box>
          <Box p={1} className='content-box'>
            {React.cloneElement(children, {...{pageRef }, pageData: {...pageData}})}
          </Box>
        </Paper>
      </Grid>
    </Grid>);
});
 
export default BlankPage;
