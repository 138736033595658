import { httpService } from 'core/services/httpService';   

export async function getBusinesseCenters(options){
  return httpService.GET({url: '/ep/pg', }, options);
};

export async function addBusinesseCenter(data, options){
  return httpService.POST({url: '/ep/pg', ...data}, options);
};

export async function updateBusinessCenter(data, options){
  const { businessId } = options
  return httpService.PUT({url: `/${businessId}/pg`, ...data}, options);
};

export async function getBusinesseDetails({businessId}, options={}){
  return httpService.GET({url: `/${businessId}/pg/details`}, options);
};

export async function businessCenterLinkBankAccount(data){
  const { businessId, accountId } = data.pathParam
  return httpService.get({url: `/business-centers/${businessId}/link-bank-account/${accountId}`, ...data});
};

export async function deleteBusinesseCenter({businessId}, options={}){
  return httpService.DELETE({url: `${businessId}/pg`}, options);
};

export async function verifyLicensedPropertiesCount(options={}){
  return httpService.GET({url: `ep/pg/checkPGLicenceCount`}, options);
};

export async function updateBusinessProfile() {}