import React, { useEffect, useRef } from 'react';
import { Paper, Grid, Typography, Stack } from '@mui/material';
import { Box } from '@mui/system';
import { useTheme } from '@mui/material/styles';
import AppBreadcrumbs from 'core/components/breadcrumb/';
import { ApiProgress } from 'core/components/Loader';
import { logger } from 'core/utils/logger';

const BlankWithPageTitle = React.memo(({title, subTitle, pageData, children, breadcrumbs=[], hideBreadcrumb=false, pageBorder=true}) => {
  logger.info('Page layout mounting...');
  
  const { palette } = useTheme();
  const pageRef = useRef(null);

  useEffect(() => {
    logger.debug('Page layout mounted');
  
    return () => {}
  }, []);
  

  return (
    <Grid container pt={pageBorder && (!hideBreadcrumb && breadcrumbs?.length === 0) ? 2 : 0 }>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
        <AppBreadcrumbs breadcrumbs={hideBreadcrumb ? [] : breadcrumbs} />
      </Grid>  
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
        <Paper elevation={0} 
          sx={{ minHeight: '80vh', mt: 2, minWidth: '244px', border: pageBorder ? '1px solid #ededed' : 'none', p: 2  }} 
          ref={pageRef} 
          className='app-page-container'
        >
          <Box className='loading-wrapper'></Box>
          <Stack direction="row" justifyContent="space-between" >
            <Paper 
              elevation={0}
              sx={{
                p: 1,
                px: pageBorder ? 1.5 : 0,
                // px: 0,
                color: palette.primary.main, 
                width: '100%', 
                // color: '#ffffff',
                borderBottomLeftRadius: 0,
                borderBottomRightRadius: 0,
              }}
            >
              {title ? <Typography className="page-title accent-secondary" variant='h6' component="h6" sx={{fontWeight: 400}}>{title}</Typography> : null}
              {title ? <Typography variant='caption' color="grey" component="p" sx={{fontWeight: 400}}>{subTitle}</Typography> : null}
            </Paper>
          </Stack>
          <Box sx={{height: '4px'}}>
            <Box className='api-progress-container'>
                <ApiProgress />
            </Box>
          </Box>
          <Box p={1} className='content-box'>
            {React.cloneElement(children, {...{pageRef}, pageData: {...pageData}})}
          </Box>
        </Paper>
      </Grid>
    </Grid>);
});
 
export default BlankWithPageTitle;
