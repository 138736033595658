import React, { memo,  useContext, useMemo } from 'react';
import { Drawer as BaseDrawer, Toolbar, Box, useMediaQuery } from '@mui/material';
import {  styled } from '@mui/material/styles';
import { AppContext } from 'core/context';
import NavbarMenuItems from 'routes/navbarItems';
import NavList from './NavList';
import { useTheme } from '@mui/material/styles';
import {  useParams } from 'react-router-dom';
import SimpleBar from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';
import { generateDrawerLinksPath } from 'utils';

// const drawerWidth = 280;
const drawerWidth = 88;
const drawerExpandWidth = 280;
const defaultNavbarMenuItems = [];
const menuItems = [
  ...defaultNavbarMenuItems,
  ...NavbarMenuItems
];

const Drawer = styled(BaseDrawer, { shouldForwardProp: (prop) => prop !== 'open' && prop !== 'isBelowMediumScreenMatches' })(
  ({ theme, open, isBelowMediumScreenMatches }) => ({
    '&.MuiDrawer-root': {
      width: isBelowMediumScreenMatches && open ? '100%' : 'auto',
      backgroundColor: '#fff',
      zIndex: 1201,
    },
    '& .MuiDrawer-paper': {
      backgroundColor:  'rgb(189 2 60 / 5%)',
      // padding: '0 0.8rem',
      // borderRight: theme.palette.secondaryContainer,  
      borderRight: 'none',
      // color: theme.palette.primary.light, 
      position: 'relative',
      whiteSpace: 'nowrap',
      width: drawerWidth, 
      // overflowX: 'hidden',
      // marginLeft: `-${drawerWidth}px`,
      boxShadow: 'none',
      // transition: theme.transitions.create('margin', {
      //   easing: theme.transitions.easing.easeIn,
      //   duration: theme.transitions.duration.enteringScreen,
      // }),
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.easeIn,
        duration: theme.transitions.duration.enteringScreen - 100,
      }),
      boxSizing: 'border-box',
      // ...(open && {
      //   overflowX: 'hidden',
      //   transition: theme.transitions.create('margin', {
      //     easing: theme.transitions.easing.easeOut,
      //     duration: theme.transitions.duration.leavingScreen,
      //   }),
      //   width: drawerWidth,
      //   // width: theme.spacing(7),
      //   // [theme.breakpoints.up('sm')]: {
      //   //   width: theme.spacing(9),
      //   // },
      //   marginLeft: 0,
      // }),

      ...(open && {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: drawerExpandWidth,
        // width: theme.spacing(7),
        // [theme.breakpoints.up('sm')]: {
        //   width: theme.spacing(9),
        // },
        // marginLeft: 0,
      }),

      // If below medium screen
      ...(isBelowMediumScreenMatches && {
        width: '100%',
        position: 'absolute',
        left: 0,
        right: 0,
        top: 'auto',
        bottom: 0,
        overflowX: 'hidden',
        display: open ? 'block' : 'none'
      }),

    },
    '& .MuiDrawer-paper:hover': {
      boxShadow: 'rgba(0, 0, 0, 0.2) 0px 3px 3px -2px, rgba(0, 0, 0, 0.14) 0px 3px 4px 0px, rgba(0, 0, 0, 0.12) 0px 1px 8px 0px',
      // transition: theme.transitions.create('box-shadow', {
      //   easing: theme.transitions.easing.easeOut,
      //   duration: theme.transitions.duration.leavingScreen,
      // }),
      width: open ?  '100%' : drawerExpandWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.leavingScreen,
        delay: 450
      }),
    },
    // '& .Mui-selected': {
    //   color: theme.palette.secondary.main,
    //   // borderLeft: `6px solid ${theme.palette.secondary.main}`,
    //   // backgroundColor: theme.palette.primary.lighter,
    //   backgroundColor: theme.palette.primary.lighter,
    // },
    '& .Mui-selected .MuiListItemIcon-root': {
      color: theme.palette.onPrimaryContainer,
    },
    '& .MuiListItemButton-root.Mui-selected': {
      color: theme.palette.onPrimaryContainer,
      pointerEvents: 'none',
      // borderRight: `6px solid ${theme.palette.primary.main}`,
      backgroundColor: 'rgb(189 2 60 / 12%)',
      fontWeight: 600,
    },
    '& .MuiListItemButton-root.Mui-selected:hover': {
      // backgroundColor: theme.palette.primary.lighter,
    },
    '& .MuiListItemButton-root:hover': {
      // color: theme.palette.primary.main,
      // borderRight: `6px solid ${theme.palette.primary.main}`,
      // backgroundColor: theme.palette.primary.lighter1,
    },
    '& .MuiButtonBase-root': {
      borderRadius: '24px',
      margin: '10px 0', 
      color: theme.palette.black,
    }
  }),
);

const Navbar = () => {
  /* Hooks */
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.down('lg'));
  const isBelowMediumScreenMatches = useMediaQuery(theme.breakpoints.down('md'));
  const { isSideDrawerOpen, updateAppContext, isLicensed } = useContext(AppContext);
  // const [busineesInfo, setBusineesInfo] = useState(false);
  let pathParams = useParams();
  // Generate actual path for dynamic path
  const menuItemWithPath = useMemo(() => generateDrawerLinksPath(menuItems, pathParams), [pathParams]);
  // get business details
  // useEffect(() => {
  //   const { businessId } = pathParams;
  //   if(businessId){
  //     const business = isValidBusinessId(businesses, businessId);
  //     if(business){
  //       setBusineesInfo({...business});
  //     }
  //   }else{
  //     setBusineesInfo(false);
  //   }
  // }, [businesses, pathParams]);

  function onNavLinkClick() {
    if(matches){
      updateAppContext({
        isSideDrawerOpen: false
      });
    }
  }

  // const handleClickAway = useCallback((e) => {
  //   const isMenuIcon = e.target.closest('#navMenubutton');
  //   if(!isMenuIcon && matches && isSideDrawerOpen){
  //     updateAppContext({
  //       isSideDrawerOpen: false
  //     });
  //   }
  // },[matches, updateAppContext, isSideDrawerOpen]);

  return menuItemWithPath?.length > 0 ? (
    // <ClickAwayListener onClickAway={handleClickAway}>
      <Drawer variant="permanent" className="drawer-mobile" open={isSideDrawerOpen} isBelowMediumScreenMatches={isBelowMediumScreenMatches} >
        <Toolbar sx={{mt: 1}}>
          {/* <Link component={RouteLink} to="/" color="secondary">
            <Avatar
              alt="PG manager"
              src={`assets/images/logo.png`}
              sx={{ width: 126, height: 'auto', }}
              variant="square"
            />
          </Link> */}
        </Toolbar>
        <SimpleBar 
          style={{ 
            // height: {xs: `calc(100vh - ${pathParams.businessId ? 120 : 72}px)`, sm: 'calc(100vh - 72px)' }
            height: `calc(100vh - 72px)`,
            padding: '0 0.9rem'
          }} 
        >
          <Box 
            sx={{ 
              // pb: '60px', 
              // height: 'calc(100vh - 72px)',
              overflowX: 'hidden', 
              mt: {xs: pathParams.businessId ? 7 : 1, sm: 1 }
            }}
          >
            {/* {busineesInfo? (
              <Box sx={{p:2, pb: 0, whiteSpace: 'break-spaces'}}>
                <Typography variant="h5" component="h6"  fontSize={24}  color={theme.palette.black.main}>{busineesInfo?.name}</Typography>
                <Typography variant="caption" gutterBottom component="p" >{busineesInfo?.formattedAddress}</Typography>
              </Box>
            ): null} */}
            <Box sx={{maxWidth: 480, margin: '0 auto'}}>
              <NavList menuItems={menuItemWithPath} isSideDrawerOpen={isSideDrawerOpen} onNavLinkClick={onNavLinkClick} isLicensed={isLicensed} />
            </Box>
          </Box>
        </SimpleBar>
      </Drawer>
    // </ClickAwayListener>
  ): null;
};
 
export default memo(Navbar);
export { menuItems };
