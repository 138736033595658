import { matchRoutes, matchPath } from "react-router";

export function buildBreadcrumbs(routes, pagePath, location){
  
  const [currentLocationData] = matchRoutes(routes, location);

  // Get all routes that contain the current one. exclude current page
  const matchedRoutes = routes.filter(({ path, hideInBreadcrumb = false }) => pagePath.includes(path) && !hideInBreadcrumb && pagePath !== path).map((route)=>({...route,...matchPath({path: route.path}, route.path)})) //.map(route=>({...route,...currentLocationData}));
  
  // Map dynamic parameter values
  const crumbs = matchedRoutes.map(({ pathname, params, ...rest}) => ({
    ...rest,
    path: Object.keys(params).length
      ? Object.keys(params).reduce(
         (path, param) => path.replace(
           `:${param}`, currentLocationData.params[param]
         ), pathname
        )
      : pathname
  }));

  // If breacrumb has only one value return empty
  if(crumbs.length === 1 ){
    return [];
  }
  // Order the breadcrumbs based on the url pattern length
  const orderedBreadcrumbs = crumbs.sort((a,b)=>a.path.length-b.path.length);

  return orderedBreadcrumbs.map(({ name='Home', path }) => ({name, path}));
}
