import { AddScript } from "utils";
import { PAYMENT_GATEWAY } from "utils/appConstants";

const RazorpayCheckout = function(successHandler, failureHandler, notifyMerchantHandler){
  let Razorpay;
  const checkoutJSScriptURL='https://checkout.razorpay.com/v1/checkout.js';
  // Show Paytm window
  async function launchPaymentGateway(txnObj) {

    const { orderId, amount } = txnObj;

    // Add Paytm script
    AddScript(checkoutJSScriptURL).then(() => {
      Razorpay = window.Razorpay;
      if(Razorpay){
        const config = buildPaymentData(txnObj);
        const rzp = new Razorpay(config);
        rzp.open();
        rzp.on('payment.failed', (response) => {
          if(typeof failureHandler === 'function'){

            const respData = {
              ORDERID: orderId,
              STATUS: 'failed',
              RESPMSG: response?.error?.description,
              totalAmount: amount
            }
            failureHandler(respData, PAYMENT_GATEWAY.RAZORPAY);
          }
        });
      }
    });
  }
 
  function buildPaymentData(txnObj) {
    const { merchantId, amount, orderId, name, email, mobile } = txnObj;
     
    const options = {
      key: merchantId, // Enter the Key ID generated from the Dashboard
      amount: amount * 100,  // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
      currency: "INR",
      order_id: orderId, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
      // callback_url: callbackURL, 
      // description: 'description',
      name: 'PG Manager',
      handler: (response) =>  {
        if(typeof successHandler === 'function'){
          const respData = {
            ORDERID: orderId, TXNID: response?.razorpay_payment_id, STATUS: 'success', totalAmount: amount
          }
          successHandler(respData, PAYMENT_GATEWAY.RAZORPAY);
        }
      },
      prefill: { 
        "name": name,
        "email": email,
        "contact": `+91${mobile}`
      },
      // notes: {address: "Razorpay Corporate Office"},
      theme: {color: "#e40047"},
      hidden: {
        contact: true, 
        email: true,
      },
      retry: {
        enabled: false,
        // max_count: 1
      },
      modal: {
        confirm_close: true, 
        ondismiss: (data) => {
          if(!data && typeof notifyMerchantHandler === 'function') { 
            notifyMerchantHandler('APP_CLOSED', orderId);
          }
        }
      }
    };
    return options;
  }
  
  return {
    launch: launchPaymentGateway
  }
};
 
export default RazorpayCheckout;

