// API NAMES
export const ROUTES = {
  
  LOGIN: '/signin/',
  REGISTER: '/register/',
  PROFILE: '/my-account/profile/',
  FORGOT_PASSWORD: '/reset-password/',
  UNAUTHORIZED: '/unauthorized/',
  SERVER_DOWN: '/504/',
  MY_ACCOUNT: '/my-account/',
  // PRIVACY_POLICY: '/privacy/',
  // TERMS_OF_USE: '/terms-of-use/',
  CONTACT_US: '/contact-us/',
  ABOUT_US: '/about-us/',
  EMAIL_UNSUBSCRIPTION: '/subscriptions/email/',
  PRIVACY_POLICY: 'https://pgmanager.in/privacy/',
  TERMS_OF_USE: 'https://pgmanager.in/termsofuse/',
}

export const LOGIN_COOKIE = 'c_ptls';


export const BROWSER_STORAGE = {
  SESSION_AUTH: '_pgm$authToken',
  SESSION_REFRESH_TOKEN: '_pgm$refreshToken',
  SESSION_USER_ID: '_pgm$user',
  LOCAL_BUSINESS_INFO: '_pgm$business'
}

export const STATES = ['Andhra Pradesh', 'Arunachal Pradesh','Assam','Bihar','Chhattisgarh','Goa','Gujarat','Haryana','Himachal Pradesh','Jammu and Kashmir','Jharkhand','Karnataka','Kerala','Madhya Pradesh','Maharashtra','Manipur','Meghalaya','Mizoram','Nagaland','Odisha','Punjab','Rajasthan','Sikkim','Tamil Nadu','Telangana','Tripura','Uttarakhand','Uttar Pradesh','West Bengal','Andaman and Nicobar Islands','Chandigarh','Dadra and Nagar Haveli','Daman and Diu','Delhi','Lakshadweep']
