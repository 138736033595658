import { Breadcrumbs, Box, Link } from '@mui/material';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

const AppBreadcrumbs = ({breadcrumbs=[]}) => {
  return breadcrumbs?.length > 0 ? (
    <Box sx={{p: 1, mt: 1.5}}>
      <Breadcrumbs separator="›" aria-label="breadcrumb">
        {breadcrumbs.map(({name, path }, i) => (
          <Link component={RouterLink} underline="hover" variant="body2" color="secondary" to={path} key={i}>
            {name}
          </Link>
        ))}
      </Breadcrumbs>
    </Box>
  ): null;
}
 
export default React.memo(AppBreadcrumbs);
