import { AddScript } from "utils";
import { PAYMENT_GATEWAY } from "utils/appConstants";

const EaseBuzzCheckout = function(successHandler, failureHandler, notifyMerchantHandler){
  let EasebuzzCheckout;
  const checkoutJSScriptURL='https://ebz-static.s3.ap-south-1.amazonaws.com/easecheckout/v2.0.0/easebuzz-checkout-v2.min.js';
  // Show window
  async function launchPaymentGateway(txnObj) {

    const { merchantId } = txnObj;

    // Add Paytm script
    AddScript(checkoutJSScriptURL).then(() => {
      EasebuzzCheckout = window.EasebuzzCheckout;
      if(EasebuzzCheckout){
        const options = buildPaymentData(txnObj);
        var easebuzzCheckout = new EasebuzzCheckout(merchantId, 'prod')
        easebuzzCheckout.initiatePayment(options);
      }
    });
  }
 
  function buildPaymentData(txnObj) {
    const { transactionToken, amount } = txnObj;
     
    const options = {
      access_key: transactionToken, // access key received via Initiate Payment
      onResponse: (response) => {
        const { error, message, status, txnid, error_Message } = response; 

        // catche checkout window close event
        // if(status === "userCancelled" && typeof notifyMerchantHandler === 'function') {
        //   notifyMerchantHandler('APP_CLOSED', 'NA');
        // }
        
        if(error === true || status === "dropped" || status === "failure"){
          const respData = {
            ORDERID: 'NA',
            STATUS: 'failed',
            TXNID: txnid,
            RESPMSG: error_Message || message,
            totalAmount: amount
          }
          typeof failureHandler === 'function' && failureHandler(respData, PAYMENT_GATEWAY.EASEBUZZ);
        }

        // Success status
        if(status === "success") {
          if(typeof successHandler === 'function'){
            const respData = {
              ORDERID: 'NA', TXNID: txnid, STATUS: 'success', totalAmount: amount
            }
            successHandler(respData, PAYMENT_GATEWAY.EASEBUZZ);
          }
        }

      },
      theme: "#e40047" // color hex
    };
    return options;
  }
  
  return {
    launch: launchPaymentGateway
  }
};
 
export default EaseBuzzCheckout;

