import React, { useEffect, useRef } from 'react';
import { logger } from 'core/utils/logger';

const BlankPublicPage = React.memo(({pageData, children}) => {
  logger.info('BlankPublicPage layout mounting...');
  
  const pageRef = useRef(null);

  useEffect(() => {
    logger.debug('BlankPublicPage layout mounted');
    return () => logger.debug('BlankPublicPage layout unmounted');
  }, []);
  
  return React.cloneElement(children, {...{pageRef }, pageData: {...pageData}});
});
 
export default BlankPublicPage;
