import {
    getButtonStyles,
    // getAccordion,
    // getAlert,
    // getAppBar,
    // getBadge,
    // getCard,
    // getCssBaseline,
    // getDrawer,
    // getFab,
    // getListItem,
    // getListItemButton,
    // getListItemIcon,
    // getMenu,
    // getSwitch,
    // getToggleButton,
    // getToggleButtonGroup,
    // getTooltip
} from '../components';



export const getMUIComponents = (theme) => {
    //const { palette } = theme;
    return {
        components: {
            ...getButtonStyles(theme)
            // ...getCssBaseline(theme),
            // ...getAccordion(theme),
            // ...getAlert(theme),
            // ...getAppBar(theme),
            // ...getBadge(theme),
            // ...getButton(theme),
            // ...getCard(theme),
            // ...getDrawer(theme),
            // ...getFab(theme),
            // ...getListItem(theme),
            // ...getListItemButton(theme),
            // ...getListItemIcon(theme),
            // ...getMenu(theme),
            // ...getSwitch(theme),
            // ...getToggleButton(theme),
            // ...getToggleButtonGroup(theme),
            // ...getTooltip(theme),
        }
    };
};