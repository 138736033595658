
import { alpha } from '@mui/material/styles';
import { StateLayer, getStateLayerColor } from '../utils';

function capitalizeFirstLetter(string='') {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const getButtonStyles = (theme) => {
  const { palette } = theme;  
  return {
      MuiButton: {
          styleOverrides: {
              root: {
                  borderRadius: 24,
                  textTransform: 'none',
                  // fontWeight: 'bold',
                  boxShadow: 'none',
                  '&:has(>svg)': {
                      padding: '8px',
                      borderRadius: '50%',
                      minWidth: '1em',
                      minHeight: '1em'
                  },
                  '&:hover': {
                    boxShadow: theme.shadows[1]
                  },
              }
          },
          variants: [
              {
                  props: { variant: 'elevated' },
                  style: ({ownerState: { color = 'primary', bgopacity=1 }}) => ({
                      // boxShadow: theme.shadows[1],
                      backgroundColor: alpha(palette.surfaceContainerLow.main, bgopacity),
                      color: palette[color].main,
                      '&:hover': {
                          background: getStateLayerColor(StateLayer.Hover, palette.surfaceContainerLow.main, palette[color].main),
                          boxShadow: theme.shadows[1]
                      },
                      '&:focus': {
                          background: getStateLayerColor(StateLayer.Focus, palette.surfaceContainerLow.main, palette[color].main)
                      },
                      '&:active': {
                          background: getStateLayerColor(StateLayer.Press, palette.surfaceContainerLow.main, palette[color].main)
                      },
                      '&.Mui-disabled': {
                          backgroundColor: alpha(palette.onSurface.main, 0.12),
                          color: alpha(palette.onSurface.main, 0.38),
                          boxShadow: theme.shadows[0]
                      },
                  })
              },
              {
                props: { variant: 'filled' },
                style: ({ownerState: { color='primary', bgopacity=1 }}) => {
                  const colorPalette = palette[color] || palette['primary'];
                  const onBtnColorKey = `on${capitalizeFirstLetter(color)}`;

                  return {
                    backgroundColor: alpha(colorPalette.main, bgopacity),
                    color: palette[onBtnColorKey].main,
                    // boxShadow: theme.shadows[0],
                    '&.Mui-disabled': {
                        backgroundColor: alpha(palette.onSurface.main, 0.12),
                        color: alpha(palette.onSurface.main, 0.38),
                        boxShadow: theme.shadows[0]
                    },
                    '&:hover': {
                        backgroundColor: getStateLayerColor(StateLayer.Hover, colorPalette.main, palette[onBtnColorKey].main),
                        boxShadow: theme.shadows[1],
                    },
                    '&:focus': {
                        backgroundColor: getStateLayerColor(StateLayer.Focus, colorPalette.main, palette[onBtnColorKey].main),
                        boxShadow: theme.shadows[0],
                    },
                    '&:active': {
                        backgroundColor: getStateLayerColor(StateLayer.Press, colorPalette.main, palette[onBtnColorKey].main),
                        boxShadow: theme.shadows[0],
                    }
                  }
                }
              },
              {
                props: { variant: 'tonal' },
                style: ({ownerState: { color='secondary', bgopacity=1 }}) => {
                  const containerKey = `${color}Container` ;
                  const onContainerKey = `on${capitalizeFirstLetter(color)}Container`;

                  const containerPalette = palette[containerKey] || palette['secondaryContainer'];
                  const onContainerPalette = palette[onContainerKey] || palette['onSecondaryContainer'];

                  return {
                    backgroundColor: alpha(containerPalette.main, bgopacity),
                    color: onContainerPalette.main,
                    // boxShadow: theme.shadows[1],
                    '&.Mui-disabled': {
                        backgroundColor: alpha(palette.onSurface.main, 0.12),
                        color: alpha(palette.onSurface.main, 0.38),
                        boxShadow: theme.shadows[0]
                    },
                    '&:hover': {
                        backgroundColor: getStateLayerColor(StateLayer.Hover, containerPalette.main, onContainerPalette.main),
                        boxShadow: theme.shadows[1],
                    },
                    '&:focus': {
                        backgroundColor: getStateLayerColor(StateLayer.Focus, containerPalette.main, onContainerPalette.main),
                        boxShadow: theme.shadows[0],
                    },
                    '&:active': {
                        backgroundColor: getStateLayerColor(StateLayer.Press, containerPalette.main, onContainerPalette.main),
                        boxShadow: theme.shadows[0],
                    }
                  }
                }
              },
              {
                  props: { variant: 'outlined' },
                  style: ({ownerState: { color='primary' }}) => {
                    const colorPalette = palette[color] || palette['primary'];
                   
                    return {
                      //backgroundColor: palette.surface.main,
                      color: colorPalette.main,
                      borderColor: palette.outline.main,
                      borderWidth: '1px',
                      boxShadow: theme.shadows[0],
                      '&.Mui-disabled': {
                          borderColor: alpha(palette.onSurface.main, 0.12),
                          color: alpha(palette.onSurface.main, 0.38),
                      },
                      '&:hover': {
                          backgroundColor: getStateLayerColor(StateLayer.Hover, palette.surface.main, colorPalette.main),
                          borderColor: getStateLayerColor(StateLayer.Hover, palette.outline.main, colorPalette.main),
                      },
                      '&:focus': {
                          backgroundColor: getStateLayerColor(StateLayer.Focus, palette.surface.main, colorPalette.main),
                          borderColor: getStateLayerColor(StateLayer.Focus, colorPalette.main, colorPalette.main),
                      },
                      '&:active': {
                          backgroundColor: getStateLayerColor(StateLayer.Press, palette.surface.main, colorPalette.main),
                          borderColor: getStateLayerColor(StateLayer.Press, palette.outline.main, colorPalette.main),
                      }
                    }
                  }
              },
              {
                  props: { variant: 'text' },
                  style: ({ownerState: { color='primary' }}) => {
                    const colorPalette = palette[color] || palette['primary'];

                    return {
                      backgroundColor: 'transparent',
                      color: colorPalette.light,
                      boxShadow: theme.shadows[0],
                      padding: '5px 15px',
                      '&.Mui-disabled': {
                          color: alpha(palette.onSurface.main, 0.38),
                      },
                      '&:hover': {
                          backgroundColor: getStateLayerColor(StateLayer.Hover, palette.surface.main, colorPalette.main),
                          boxShadow: theme.shadows[0],
                        //   color: colorPalette.main
                      },
                      '&:focus': {
                          backgroundColor: getStateLayerColor(StateLayer.Focus, palette.surface.main, colorPalette.main),
                          boxShadow: theme.shadows[0],
                      },
                      '&:active': {
                          backgroundColor: getStateLayerColor(StateLayer.Press, palette.surface.main, colorPalette.main),
                          boxShadow: theme.shadows[0],
                      },
                    }
                  }
              }
          ]
      }
  };
}