import pino from 'pino';
// import {default as pretty} from 'pino-pretty';

const LOG_LEVEL_KEY = 'LOG_LEVEL';
 
function isValidLevel(level = 'invalid') {
  const { values } = pino().levels;
  return typeof values[level] === 'number' || level === 'silent';
}
 
function getLogLevel() {
  const defaultLevel = 'silent';
  let level = process.env.REACT_APP_LOG_LEVEL || defaultLevel;
 
  if (window && window.localStorage) {
    const logLevelFromStorage = localStorage.getItem(LOG_LEVEL_KEY);
    if (logLevelFromStorage) {
      level = logLevelFromStorage || level;
    }
  }
 
  if (isValidLevel(level)) {
    return level;
  }
 
  return defaultLevel;
}

const formatters = {
  bindings (bindings) {
    return { pid: bindings.pid, hostname: bindings.hostname }
  },
  level(label /*, number*/) {
    return { level: label };
  },
  log(object) {
    return {
      ...object,
      timestamp: new Date().toISOString(),
    };
  },
};

export const logger = pino(
  {
    formatters,
    // browser: {asObject: true},
    // prettyPrint: true,
    // enabled: true,
    // messageKey: 'message',
    level: getLogLevel(),
  // timestamp: pino.stdTimeFunctions.isoTime,
  },
  // stream
);

export const httpLogger = pino(
  {
    browser: {
      asObject: true,
    },
    // formatters,
    messageKey: 'message',
    level: getLogLevel(),
  },
);
