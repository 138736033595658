import { AppContext, AuthContext } from "core/context";
import { getAppState } from "core/services/auth";
import { logger } from "core/utils/logger";
import React, { useCallback, useContext, useEffect, useState } from "react"
import { useNavigate } from 'react-router-dom';
import { ROUTES } from "routes/constants";

const RenderBusinessPage = ({children, breadcrumbs, businessId}) => {
  logger.info('[RenderBusinessPage] mounting...')
  const { updateAppContext, businessId: selectedBusinessId } = useContext(AppContext);
  const { updateAuthContext } = useContext(AuthContext);

  const [allowRenderPage, setAllowRenderPage] = useState(false);
  let navigate = useNavigate();

  const getBusinessData = useCallback((businessId) => {
    const data = {pgId:  businessId};
    setAllowRenderPage(false);
    const controller = new AbortController();
    getAppState({data}, {signal: controller.signal, backgroundRequest: false}).then(({response={}, error, statusCode}) => {
      if(statusCode !==  200) return;

      if(error){
        navigate(ROUTES.BUSSESSES);
      }
      setAllowRenderPage(true);
      const { email, name, permissions=[], pgDisplayId, pgName, role, uuid, pgType, mobile } = response;
      const appPermissions = [...(permissions||[]), ...(role?.toLowerCase() === 'owner' ? ['Add Business'] : [])];
      const isOwner = role?.toLowerCase() === 'owner';
      const isPartner = role?.toLowerCase() === 'partner';
      const isManager = role?.toLowerCase() === 'manager';
      const authData = {permissions: appPermissions, data: { isOwner, isPartner, isManager, name, email, role, uuid, mobile }, userId: uuid};
      
      // Update app context with business details 
      updateAppContext({ permissions: appPermissions, businessId, businessDisplayId: pgDisplayId, businessName: pgName, businessType: pgType });
      updateAuthContext({...authData});
    });
    return controller;
  }, [navigate, updateAppContext, updateAuthContext]);

  useEffect(() => {
    logger.info('[RenderBusinessPage] mounted.');
    let controller;
    if(businessId && businessId !== selectedBusinessId) {
      controller = getBusinessData(businessId);
    }else if(selectedBusinessId){
      setAllowRenderPage(true);
    }else{
      updateAppContext({  
        businessId: null,
        permissions: null, 
        businessDisplayId: null, 
        businessName: null,
        businessType: null,
      })
    }

    return () => {
    logger.info('[RenderBusinessPage] unmounted.');
      controller?.abort();
    }
   }, [businessId, getBusinessData, selectedBusinessId, updateAppContext]);

  return allowRenderPage ? React.cloneElement(children, {...{breadcrumbs}}) : null;
}
 
export default RenderBusinessPage;