import React, {useEffect, useContext } from 'react';
import { AppContext, AuthContext } from 'core/context/';
import { useNavigate, generatePath } from 'react-router-dom';
import { ROUTES } from 'routes/constants';

const HomePageRedirect = () => {
  const { isUserLoggedIn } = useContext(AuthContext);
  const { businessId } = useContext(AppContext);
  const navigate = useNavigate();

  useEffect(() => {
    if(isUserLoggedIn) {
      if(businessId) navigate(generatePath(ROUTES.HOME, {businessId}));
      else navigate(ROUTES.BUSSESSES);
    }else{
      navigate(ROUTES.LOGIN);
    }
  }, [isUserLoggedIn, businessId, navigate]);
  
  
  return null;
}
 
export default React.memo(HomePageRedirect);
