// import Routes from 'core/routes/index.js';
// import { matchPath } from "react-router";
import authService from 'core/services/authService';

// Get common values from array
export const intersection = (arr1=[], arr2=[]) => arr1.filter(x => arr2.includes(x));

// The difference will output the elements from array 2 that are not in the array 2
export const difference = (arr1=[], arr2=[]) => arr1.filter(x => !arr2.includes(x));

export const isRolesPermitted = (rolesAssigned = [], userRoles=[]) => {
  if(rolesAssigned.length === 0){
    return true;
  }
  // const matchedRoles = rolesAssigned.filter(value => userRoles.includes(value));
  const matchedRoles = intersection(rolesAssigned, userRoles);

  if(matchedRoles.length > 0){
    return true
  }
  return false;
}

export function isUserRoleExists(roles) {
  // console.log('authService.userRoles', authService.userRoles, roles);
  return isRolesPermitted(roles, authService.userRoles);
}

export function setCookie(cname, cvalue, exMins) {
  var d = new Date();
  d.setTime(d.getTime() + (exMins*60*1000));
  var expires = "expires="+d.toUTCString();  
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

// export function checkIsAuthRoute(url) {
//   let isAuthRoute = false;
//   for(let i=0; i < Routes.length; i++) {
//     const route = Routes[i];
//     // console.log('route',route);
//     if(route?.enableAuth === true && matchPath({path:route.path},url)){
//       isAuthRoute = true;
//       break;
//     }
//   }
//   return isAuthRoute;
// }

export function getTimeDiffInSec(date1, date2){
  // const diffSlots = {
  //   sec: 1000,
  //   miutes: 60,
  //   hours: 24,
  // }
  try{
    const d1 = new Date(date1);
    const d2 = new Date(date2);
    const diff = d1.getTime() - d2.getTime();
    return Math.round((diff/1000));
  }catch(err){
    return null;
  }
}

export function parseJwt(token) {
  try {
    return JSON.parse(atob(token.split(".")[1]));
  } catch (e) {
    return null;
  }
};

export function fileSaver(blob, fileName, type='octet/stream') {
  const a = document.createElement('a');
  document.body.appendChild(a);
  let blobObj;
  if(type === 'application/pdf') {
    blobObj = blob;
  }else{
    blobObj = new Blob([blob], { type });
  }
  const url = window.URL.createObjectURL(blobObj);
  a.href = url;
  a.download = fileName;
  a.click();
  window.URL.revokeObjectURL(url);
  a.remove();
}

// export function debounce(fn, ms) {
//   let timer
//   return _ => {
//     clearTimeout(timer)
//     timer = setTimeout(_ => {
//       timer = null
//       fn.apply(this, arguments)
//     }, ms)
//   };
// }


export const debounce = (fn, delay) => {
  let timer = null;
  return function () {
    const args = Array.prototype.slice.call(arguments || {}) || [];
    clearTimeout(timer);
    timer = setTimeout(() => {
      fn(...args);
    }, delay);
  };
};

export const apiBaseUrl = `${process.env.REACT_APP_API_ORIGIN ? process.env.REACT_APP_API_ORIGIN : window.location.origin}${process.env.REACT_APP_API_PATH}`;

export function numberFormatter(number=0) {
  return (parseFloat(number) || 0).toLocaleString('en-IN')
}

export function blobToBase64(blob) {
  return new Promise((resolve, _) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    reader.readAsDataURL(blob);
  });
}