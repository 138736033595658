import React, {useCallback, useEffect, useState} from 'react';
import { businessDetails  } from 'core/services/businessService';
import authService from 'core/services/authService';
import { isRolesPermitted } from 'core/utils/common';

const defaults = {
  isSideDrawerOpen: false,
  businesses: [],
  businessId: null,
  permissions: null, 
  businessDisplayId: null, 
  businessName: null,
  businessType: null,
  isLicensed: null,
};
const AppContext = React.createContext(defaults);



const AppContextProvider = (props) => {
  const userId = authService.getUserId();
  const browserStoredBusinessId = businessDetails.getBrowserStoredBusinessId(userId);

  const [context, setContext] = useState({...defaults, ...(browserStoredBusinessId ? {businessId: browserStoredBusinessId} : {})});

  const updateAppContext = useCallback((contextValues={}) => {
    setContext((prevContext) => ({
      ...prevContext,
      ...contextValues
    }));
  }, []);

  const resetContext = useCallback(() => {
    setContext({...defaults});
  }, []);

  // set business iss license or not
  useEffect(() => {
    if(context?.businessId && context?.businesses?.length > 0){
    setContext((prevContext) => {
      const { businessId, businesses=[]} = prevContext || {};
      
        const [selectedBusiness={}] = businesses.filter(business => business?.uuid === businessId);
        const { licenceExpiryDaysCount = 0 } = selectedBusiness
        const isLicensed =  licenceExpiryDaysCount > 0 ? true : false;
        return {...prevContext, isLicensed};
      });
    }
  }, [context?.businessId, context?.businesses])
  

  const setPermissionStates = useCallback((permissions=[]) => {
    const isBusinessEditable = isRolesPermitted(['Modify PG'], permissions);
    const isBusinessLicenseManageable = isRolesPermitted(['Manage licence'], permissions);
    const isBusinessLicenseRenewalAllowed = isRolesPermitted(['Renew licence'], permissions);
    const isAdditionalBedsPurchaseAllowed = isRolesPermitted(['Buy additional beds'], permissions);
    const isSendRentDueRemindersAllowed = isRolesPermitted(['Send rent due reminders'], permissions);

    const isConfigureSMSNotificationsAllowed = isRolesPermitted(['Configure SMS Notifications'], permissions);
    const isViewSMSCreditsAllowed = isRolesPermitted(['View SMS Credits'], permissions);
    const isPurchaseSMSCreditsAllowed = isRolesPermitted(['Purchase SMS Credits'], permissions);
    
    const isConfigureWhatsAppNotificationsAllowed = isRolesPermitted(['Configure WhatsApp Notifications'], permissions);
    const isViewWhatsAppCreditsAllowed = isRolesPermitted(['View WhatsApp Credits'], permissions);
    const isPurchaseWhatsAppCreditsAllowed = isRolesPermitted(['Purchase WhatsApp Credits'], permissions);

    const isPurchaseKYCCreditsAllowed = isRolesPermitted(['Purchase KYC Credits'], permissions);
    const isViewKYCCreditsAllowed = isRolesPermitted(['View KYC Credits'], permissions);
    const isSendKYCRequestAllowed = isRolesPermitted(['Send KYC Request'], permissions);
    
    return { isBusinessEditable, isBusinessLicenseManageable, isBusinessLicenseRenewalAllowed, isAdditionalBedsPurchaseAllowed, isPurchaseWhatsAppCreditsAllowed,
      isViewWhatsAppCreditsAllowed, isViewSMSCreditsAllowed, isSendRentDueRemindersAllowed, isPurchaseSMSCreditsAllowed, isPurchaseKYCCreditsAllowed,
      isViewKYCCreditsAllowed, isSendKYCRequestAllowed, isConfigureSMSNotificationsAllowed, isConfigureWhatsAppNotificationsAllowed
    }
  }, []);
  
  useEffect(() => {
    if(context?.permissions){
      const permissionStates = setPermissionStates(context?.permissions);
      setContext((prevContext) => ({
        ...prevContext,
        permissionStates
      }));
    }
  }, [context?.permissions, setPermissionStates]);

  //store business id in browser local storage
  useEffect(() => {
    if(context?.businessId){
      businessDetails.storeBusinessIdInBrowser(userId, context.businessId)
    }
    businessDetails.businessId = context.businessId;
  }, [userId, context?.businessId]);
  
  return (
    <AppContext.Provider value={{...context, updateAppContext, resetContext}}>
      {props.children}
    </AppContext.Provider>
  );
}
 
export default AppContextProvider;
export { AppContext };
