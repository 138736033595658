import React, { useCallback, useContext, useEffect } from 'react';
import { Avatar, Box, CircularProgress, CssBaseline, Typography } from '@mui/material';
import { getAppState } from 'core/services/auth';
import { AuthContext, AppContext } from "core/context";
import { businessDetails } from 'core/services/businessService';
import { useLocation, useNavigate, generatePath } from 'react-router-dom';
import { ROUTES } from 'routes/constants';
import { getBusinesseCenters } from 'services/businesseCenters';

const AppLoading = ({businessId=null}) => {

  const { updateAuthContext, userId } = useContext(AuthContext);
  const { updateAppContext } = useContext(AppContext);
  let navigate = useNavigate();
  let { pathname } = useLocation();

  const getAppLoginStatus = useCallback((businessId, signal) => {

    const pgId = businessDetails.getBrowserStoredBusinessId(userId);
    const data = {pgId: pgId || businessId || 'ep'};

    getAppState({data}, {signal}).then(({response={}, error, statusCode}) => {
      if(statusCode !==  200) return;
      setTimeout(async() => {
        const { email, name, permissions=[], pgDisplayId, pgName, role, uuid, pgType, mobile } = response;
        const isOwner = role?.toLowerCase() === 'owner';
        const isPartner = role?.toLowerCase() === 'partner';
        const isManager = role?.toLowerCase() === 'manager';
        const appPermissions = [...(permissions||[]), ...(role?.toLowerCase() === 'owner' ? ['Add Business'] : [])];
        const authData = {permissions: appPermissions, data: { isOwner, isPartner, isManager, name, email, role, uuid, mobile }, userId: uuid};
        // Update app context with business details 
        const { response: businesses=[] } =  await getBusinesseCenters();
        if(pgDisplayId){
        updateAppContext({ permissions: appPermissions, businessDisplayId: pgDisplayId, businessName: pgName, businesses, businessType: pgType, businessId });
        }else {
          updateAppContext({businesses, permissions: appPermissions, businessId: null});
        }
        // Update authenticated user details to auth context
        updateAuthContext({isUserLoggedIn: true, ...authData});
        // if root page 
        if(pathname === '/'){
          if(!pgDisplayId) {
            navigate(ROUTES.BUSSESSES);
            return;
          }
        } 
        if(pgId && pgDisplayId) navigate(generatePath(ROUTES.HOME, {businessId: pgId}));
        // return;
      }, 300);
    })
  }, [userId, updateAuthContext, pathname, updateAppContext, navigate]);
  
  useEffect(() => {
    const controller = new AbortController();
    getAppLoginStatus(businessId, controller.signal);
    return () => {
      controller.abort();
    }
  }, [getAppLoginStatus, businessId]);
  

  return (
    <Box sx={{ minHeight: 'calc(100vh - 100px)', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <CssBaseline />
      <Box sx={{ position: 'relative' }}>
        <Avatar
          alt="PG Manager Loading..."
          src="assets/images/favicon.png"
          sx={{ width: 40, height: 40 }}
        />
        <CircularProgress
          color="primary"
            size={68}
            sx={{
              color: '#0e1d34',
              position: 'absolute',
              top: -14,
              left: -14,
              zIndex: 1,
            }}
          />
          <Typography sx={{mt: 2.4, ml: '-12px'}}>Loading...</Typography>
      </Box>
    </Box>
  );
}
 
export default React.memo(AppLoading);
