import { Autocomplete, Box, Stack, TextField, Typography } from "@mui/material";
import { AppContext } from "core/context";
import { logger } from "core/utils/logger";
import React, {useContext, useEffect, useState} from "react";
import { useParams } from "react-router-dom";
import { getFormattedAddress } from "utils";
import { generatePath, useNavigate } from "react-router-dom";
import { ROUTES } from "routes/constants";

const BusinessListDropDown = () => {
  logger.info('[BusinessListDropDown] mounting...');

  const { businessId } = useParams();
  const { businesses: businessList=[] } = useContext(AppContext);
  const navigate = useNavigate();
  const [selectedBusiness, setSelectedBusiness] = useState(businessList.filter(business => business?.uuid === businessId)[0] || null);

  useEffect(() => {
    const { uuid } = selectedBusiness || {};
    if(businessId && uuid && uuid !== businessId) {
      navigate(generatePath(ROUTES.HOME, {businessId: uuid}))
    }
  }, [selectedBusiness, businessId, navigate]);
  
  return businessId ? (
    <Autocomplete
      disableClearable
      openOnFocus
      disablePortal
      autoHighlight
      options={businessList}
      // options={businessList.filter(({licenceExpiryDaysCount}) => licenceExpiryDaysCount > 0)}
      noOptionsText={'No active business profiles'}
      defaultValue={businessList.filter(business => business.uuid === businessId)[0] || null}
      getOptionLabel={(option) => (`${option.name} - ${option.displayId}`)}
      value={selectedBusiness}
      onChange={(event, newValue) => {
        setSelectedBusiness(newValue);
      }}
      renderOption={(props, option) => (
        <Box component="li" sx={{textAlign: 'left'}} {...props}>
          <Stack>
            <Typography variant="body2" color="primary" fontWeight={500}>{option.name}<Typography variant="caption" color="secondary">({option.displayId})</Typography></Typography>
            <Typography variant="caption" fontSize={12} color="text.secondary" >{getFormattedAddress(option)}</Typography>
          </Stack>
        </Box>
      )}
      sx={{
        width: 380, 
        maxWidth: '100%',
        '.MuiInputBase-root': {
          borderRadius: '24px',
          borderColor: 'none',
          backgroundColor: 'surface.main'
        } 
      }}
      size="small"
      renderInput={(params) => <TextField {...params}  sx={{ }} />}
    />
  ): null;
}
 
export default React.memo(BusinessListDropDown);