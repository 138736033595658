
export function getSelectedBusinessId(userId) {
  if(userId) {
    return window.sessionStorage.getItem(`_pgm$${userId}$bId`);
  }
  return null;
}


export function setSelectedBusinessId(userId, businessId) {
  if(userId) {
    return window.sessionStorage.setItem(`_pgm$${userId}$bId`, businessId);
  }
  return null;
}

class BusinessDetails {
  #businessId = null;

  set businessId(businessId) {
    this.#businessId = businessId;
  }

  get businessId() {
    return this.#businessId;
  }

  getBrowserStoredBusinessId(userId) {
    return window.localStorage.getItem(`_pgm$${userId}$bId`) || null;
  }
  
  storeBusinessIdInBrowser(userId, businessId) {
    window.localStorage.setItem(`_pgm$${userId}$bId`, businessId);
  }

  deleteBusinessIdInBrowser(userId, businessId) {
    if(this.getBrowserStoredBusinessId(userId) === businessId){
      window.localStorage.removeItem(`_pgm$${userId}$bId`);
      return
    }else{
      window.localStorage.removeItem(`_pgm$${userId}$bId`);
    }
  }

}

const businessDetails = new BusinessDetails();
export { businessDetails };