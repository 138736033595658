import React from 'react';
import { Box, Button, Typography, Stack } from '@mui/material';
import { logger } from 'core/utils/logger';
import GppMaybeOutlinedIcon from '@mui/icons-material/GppMaybeOutlined';
import { Container } from '@mui/system';
import { blue } from '@mui/material/colors';

const VerificationError = ({pageData}) => {
  const { info } = pageData;
  logger.info('[VerificationError] page mounting...');

  return (
    <Box sx={{
      width: '100%',
      height: '50vh',
      backgroundImage: `url(assets/images/page_layout_bg.jpg)`,
      backgroundPosition: 'center',
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',
    }}> 
      <Container maxWidth="xs" >
        <Stack alignItems="center">
          <Typography variant="h5" gutterBottom>{info?.title}</Typography>
          <Typography variant="caption" color="textSecondary" textAlign="center" gutterBottom fontSize={14}>{info?.description}</Typography>
          <Button variant='contained' color="info" sx={{mt: 2.4, mb: 1.6}}>Verify your buisness</Button>
          <Stack direction="row" columnGap={1} alignItems="center">
            <GppMaybeOutlinedIcon sx={{fontSize: 16,  color: blue[600] }} /> 
            <Typography variant="body2" fontSize={13} color="textSecondary">Your business is currently unverified</Typography>
          </Stack>
        </Stack>
      </Container>
    </Box>
  );
}
 
export default VerificationError;
