
import { createTheme } from '@mui/material/styles';
import { deepmerge } from '@mui/utils';
import { generateDesignTokens, getMUIComponents, getMUIPalette } from './utils';
import { tonalPalette } from './colors/';

const themeMode = 'light';
const lightTokens = generateDesignTokens('light', tonalPalette);
const darkTokens = generateDesignTokens('dark', tonalPalette);
const themeScheme = {
    light: lightTokens,
    dark: darkTokens,
    tones: tonalPalette
};

const muiPalette = getMUIPalette(themeMode, themeScheme);

const theme = createTheme(muiPalette);
const M3Theme = deepmerge(theme, getMUIComponents(theme));

export default M3Theme; 