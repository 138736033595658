import React, { memo, useEffect, useMemo, useState } from 'react';  
import { Button, TextField, Link, Box, Grid, Typography, CircularProgress, InputAdornment, Divider } from '@mui/material';
import { useForm, Controller } from "react-hook-form";
import { sendSignUpOTP, registerUser } from 'core/services/auth';
import { useNavigate, Link as RouteLink } from "react-router-dom";
import { useSnackbar } from 'notistack';
import { blue } from '@mui/material/colors';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import MailIcon from '@mui/icons-material/Mail';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { ROUTES } from 'core/utils/constants';
import { logger } from 'core/utils/logger';

export default function Register({formOnly = false, redirect=true, onSignupSuccess}) {
  logger.info('Register section mounting...');
  const defaultValues =  {
    firstName: '',
    lastName: '',
    mobile:'',
    email:'',
    otp: '',
  };
  /* Hooks */
  const { enqueueSnackbar } = useSnackbar();
  let navigate = useNavigate();
  const [isSiningUp, setSiningUp] = useState(false);
  const [isOTPRequested, setIsOTPRequested] = useState(false);
  const { handleSubmit, control, formState: { errors }, reset, trigger, setValue, getValues } = useForm({
    mode: 'onTouched',
    defaultValues
  });
  const mobileFieldWidth = useMemo(() => isOTPRequested ? 7 : 12,[isOTPRequested]);
  const [otpValidaity, setOtpValidaity] = useState(null);

  const resetSignupForm = () => {
    logger.debug('Register resetSignupForm');
    reset({...defaultValues});
    setSiningUp(false);
    setIsOTPRequested(false);
  };
 
  const signupUser = (data) => {
    logger.debug('Register registering user');
    setSiningUp(true);

    const { email, lastName, firstName, mobile, otp } = data;
    const body = {
      mobile,
      userDetails: {
        firstName,
        lastName,
        email,
      }
    };


    registerUser({body, otp}).then(({ error }) => {
      setSiningUp(false);
      if(error) {
        enqueueSnackbar((error?.message || error), {variant: 'error'});
        setSiningUp(false);
        return;
      }

      resetSignupForm();
      if(redirect){
        navigate(ROUTES.LOGIN);
      }
      enqueueSnackbar('You have successfully registered. Login using your credentials.', {variant: 'success'});
    });
  }

  const sendOTP = (mobile) => {
    logger.debug('Register sending OTP');
    setOtpValidaity(0);
    setValue('otp', '');
    sendSignUpOTP({data: {mobile} }).then(({ error }) => {
      if(error) {
        setIsOTPRequested(false);
        enqueueSnackbar((error), {variant: 'error'});
        return;
      }
      enqueueSnackbar('An OTP has been sent to your mobile number.', {variant: 'success'});
      setOtpValidaity(2*60);
    });
  };

  const resendOTP = () => {
    sendOTP(getValues('mobile'));
  }
  

  const onSubmit = (data) => {
    if(isOTPRequested){
      signupUser(data);
    } else {
      reset({...defaultValues});
    }
  };

  const onMobileNumberKeyUp = async (e) => {
    const mobile = e.target.value;
    if(mobile.length >= 10) {
      if(/^\d{10}$/.test(mobile)){
        setIsOTPRequested(true);
        sendOTP(mobile);
        await trigger("mobile");
        return false;
      } 
      await trigger("mobile");
   }
  }

  const clearMobileNumber = () => {
    setValue('mobile', '');
    setIsOTPRequested(false);
  }; 

  return (
    <Box component="form" noValidate onSubmit={handleSubmit(onSubmit)} sx={{px: {xs: 0, sm: 2, md: 4}}}>
      <Grid container item xs={12} sm={12} md={12} lg={12} justifyContent="center" columnSpacing={2}>
        {formOnly === false ? (
          <Typography component="h1" variant="h6" color="primary.light" sx={{mb: 1, fontWeight: 500}}>
            Create Account
          </Typography>
        ) : null}
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Controller
            control={control}
            name="firstName"
            id="firstName"
            rules={{ required: "Enter your first name"}}
            render={({field }) => (
              <TextField
                {...field}
                margin="normal"
                fullWidth
                label="First Name"
                error={!!errors.firstName}
                helperText={errors.firstName?.message}
                placeholder="First Name"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <AccountCircleIcon />
                    </InputAdornment>
                  )
                }}
              />
            )}
          />
        </Grid> 
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Controller
            control={control}
            name="lastName"
            id="lastName"
            rules={{ required: "Enter your last name"}}
            render={({field }) => (
              <TextField
                {...field}
                margin="normal"
                fullWidth
                label="Last Name"
                error={!!errors.lastName}
                helperText={errors.lastName?.message}
                placeholder="Last Name"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <AccountCircleIcon />
                    </InputAdornment>
                  )
                }}
              />
            )}
          />
        </Grid> 
        <Grid item xs={12} sm={mobileFieldWidth} md={mobileFieldWidth} lg={mobileFieldWidth} xl={mobileFieldWidth}>
          <Controller
            control={control}
            name="mobile"
            id="mobile"
            rules={{ required: "Enter your mobile number",
              pattern: {
                value: /^[0-9]{10}$/i,
                message: "Enter a valid 10 digit mobile number",
              },
              // maxLength: 10,
            }}
            render={({field }) => (
              <TextField
                {...field}
                margin="normal"
                fullWidth
                label="Mobile Number"
                error={!!errors.mobile}
                helperText={errors?.mobile?.message}
                onKeyUp={onMobileNumberKeyUp}
                disabled={isOTPRequested}
                placeholder="Mobile Number"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <PhoneIphoneIcon />
                    </InputAdornment>
                  ),
                  endAdornment: ( isOTPRequested ? 
                    <InputAdornment position="end">
                      <HighlightOffIcon sx={{cursor: 'pointer'}} onClick={clearMobileNumber}/>
                    </InputAdornment>
                  :null)
                }}
              />
            )}
          />
        </Grid> 
        {isOTPRequested ? (
          <>
            <Grid item xs={12} sm={5} md={5} lg={5} xl={5}>
              <Controller
                control={control}
                name="otp"
                id="otp"
                rules={{ required: "Enter 4-digit PIN sent to your registered mobile number",
                  pattern: {
                    value: /^[0-9]{6}$/i,
                    message: "Enter a valid 6 digit OTP",
                  },
                }}
                render={({field}) => (
                  <TextField
                    {...field}
                    margin="normal"
                    fullWidth
                    label="OTP"
                    type="text"
                    error={!!errors.otp}
                    // helperText={errors.otp?.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} textAlign="right">
              <ResendOTP resendOTP={resendOTP} otpValidaity={otpValidaity} />
            </Grid>
          </>
        ): null} 
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Controller
            control={control}
            name="email"
            id="email"
            rules={{ required: "Enter your email address",
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                message: "Enter a valid email address",
              }, 
            }}
            render={({field}) => (
              <TextField
                {...field}
                margin="normal"
                fullWidth
                label="Email"
                type="text"
                error={!!errors?.email}
                helperText={errors.email?.message}
                placeholder="Email"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <MailIcon />
                    </InputAdornment>
                  )
                }}
              />
            )}
          />
        </Grid>

        <Grid item sx={{mt:0.6}} xs={12} color="surface">
          <Typography variant='caption' color="typography.light">By signing up, I agree to the PG Manager&trade;&nbsp;
            <Link component={RouteLink} target='_new' to={ROUTES.PRIVACY_POLICY} color={blue[400]} sx={{textDecoration:'none'}}>
              Privacy Policy
            </Link>
            &nbsp;&&nbsp;
            <Link component={RouteLink} target='_new' to={ROUTES.TERMS_OF_USE} color={blue[400]} sx={{textDecoration:'none'}}>
              Terms of service
            </Link>
          </Typography> 
          <br />
        </Grid>
        <Grid container justifyContent="center" >
          <Button
            disabled={isSiningUp}
            type="submit"
            variant="contained"
            sx={{ mt: 4, mb: 2, px: 3 }}
            endIcon={isSiningUp ? <CircularProgress size={20} /> : <HowToRegIcon /> }
            size="large"
            >
            Register
          </Button>
        </Grid>
       <Grid item xs={12} sm={12} md={12} lg={12}>
       {formOnly === false ? (
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Divider sx={{ my: 1, textAlign: 'center'}}>Or</Divider>
            <Box sx={{my: 2}}>
              Already have an account? <strong><Link component={RouteLink} to={ROUTES.LOGIN} color="primary">Login</Link></strong>
            </Box>
          </Grid>
        ) : null}
      </Grid>
      </Grid>
    </Box>
  );
}

const ResendOTP = memo(function ResendOTP({resendOTP, otpValidaity}) {
  const [timeLeft, setTimeLeft] = useState(otpValidaity || -1);

  useEffect(() => {
    setTimeLeft(otpValidaity)
    
    const timer = setInterval(() => {
      setTimeLeft(pre => {
        if(pre === 0) {
          clearInterval(timer);
        }
        return pre === 0 ? 0 : pre - 1;
      });
    }, 1000);

    return () => {
      clearInterval(timer);
    }
  }, [otpValidaity])
  

  return <Button color="secondary" disabled={timeLeft > 0} onClick={resendOTP}>Resend{timeLeft > 0 ? `(${timeLeft}s)` : ''}</Button>
});
