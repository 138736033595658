import { ErrorDefault, NeutralDefault, NeutralVariantDefault, PrimaryDefault, SecondaryDefault, TertiaryDefault } from './Tone';
// import { argbFromHex, hexFromArgb, themeFromSourceColor } from '@material/material-color-utilities'

export const tonalPalette = {
    primary: PrimaryDefault,
    secondary: SecondaryDefault,
    tertiary: TertiaryDefault,
    neutral: NeutralDefault,
    neutralVariant: NeutralVariantDefault,
    error: ErrorDefault,
}
// const LEVELS = [0, 10, 20, 25, 30, 35, 40, 50, 60, 70, 80, 90, 95, 98, 99, 100];

// const generatePalette = (hexColor) => {
//     const intColor = argbFromHex(hexColor)
//     const { palettes } = themeFromSourceColor(intColor);
//     const tones = {};

//     for (const [key, palette] of Object.entries(palettes)) {
//         const tonelevel = {}
//         for (const level of LEVELS) {
//             tonelevel[level] = hexFromArgb(palette.tone(level));
//         }
//         tones[key] = tonelevel;
//     }

//     return tones
// }