import React from 'react';
import { Box, Button, Typography, Stack } from '@mui/material';
import { logger } from 'core/utils/logger';
import CardMembershipRoundedIcon from '@mui/icons-material/CardMembershipRounded';
import { Container } from '@mui/system';
import { red } from '@mui/material/colors';
import { generatePath, Link, useParams } from 'react-router-dom';
import { ROUTES } from 'routes/constants';

const LicenseError = ({pageData}) => {
  logger.info('[LicenseError] page mounting...');

  const { info } = pageData;
  const { businessId } = useParams();


  return (
    <Box sx={{
      width: '100%',
      height: '50vh',
      backgroundImage: `url(assets/images/page_layout_bg.jpg)`,
      backgroundPosition: 'center',
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',
    }}> 
      <Container maxWidth="xs" >
        <Stack alignItems="center">
          <Typography variant="h5" gutterBottom>{info?.title}</Typography>
          <Typography variant="caption" color="textSecondary" textAlign="center" gutterBottom fontSize={14}>{info?.description}</Typography>
          <Button 
            variant='contained' 
            color="secondary" 
            sx={{mt: 2.4, mb: 1.4}}  
            component={Link} 
            to={generatePath(ROUTES.BUY_BUSINESS_LICENSE, {businessId})}
          >
            Purchase license
          </Button>
          <Stack direction="row" columnGap={1} alignItems="center">
            <CardMembershipRoundedIcon sx={{fontSize: 16,  color: red[800] }} /> 
            <Typography variant="body2" fontSize={13} color="textSecondary">You need a license to use this feature</Typography>
          </Stack>
        </Stack>
      </Container>
    </Box>
  );
}
 
export default LicenseError;
