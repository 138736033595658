import React, { useEffect } from 'react';
import { createBrowserRouter, RouterProvider, Route, createRoutesFromElements} from 'react-router-dom';
import { createRoot } from 'react-dom/client';
import { logger } from 'core/utils/logger';
import routes from 'core/routes';  
import {AuthLayout, PublicLayout} from './App';
import AuthenticatedRoute from 'core/routes/authenticatedRoute';
import NotFound from 'core/pages/404/NotFound';


/**  Get Route Elements */
function getRouteElements() { 

  const publicRouteElements = [];
  const authRouteElements = [];

  routes.forEach((route, index) => {
    
    if(route?.enableAuth){
      const routeElement= <Route
        key={index}
        path={route.path}
        // loader={route?.loader ? route.loader : ()=>{}}
        element={
          <AuthenticatedRoute rolesAssigned={route?.roles} routes={routes} path={route.path} route={route}>
            <route.pageLayout {...route.pageLayoutProps}><route.component/></route.pageLayout>
          </AuthenticatedRoute>
        }
      />
      authRouteElements.push(routeElement);
    }else{
      // const routeElement = <Route key={index} path={route.path} element={<route.component />}/>;
      const routeElement = <Route key={index} path={route.path} element={<route.pageLayout {...route.pageLayoutProps}><route.component/></route.pageLayout>}/>;
      publicRouteElements.push(routeElement)
    }
  });
  return { publicRouteElements, authRouteElements };

}
const { publicRouteElements, authRouteElements } = getRouteElements();

const router = createBrowserRouter(createRoutesFromElements(
  <>
    <Route path="/" element={<PublicLayout />}>
      {publicRouteElements.map(routeElement => routeElement)}
    </Route>
    <Route path="/" element={<AuthLayout />}>
      {authRouteElements.map(routeElement => routeElement)}
    </Route>
    <Route path="*" element={<NotFound />} />
  </>
), {basename: process.env.PUBLIC_URL});

// After
function AppWithCallbackAfterRender() {
  useEffect(() => {
    logger.info('App Rendered');
  });

  return (
    <React.StrictMode>
      <RouterProvider router={router} />
    </React.StrictMode>
  );
}



/**   Create Roote element */
const container = document.getElementById('root');
const root = createRoot(container); 
root.render(<AppWithCallbackAfterRender />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
