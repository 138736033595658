const fixedTokens = {
    "primaryFixed": "#ffdadb",
    "primaryFixedDim": "#ffb2b8",
    "onPrimaryFixed": "#40000e",
    "onPrimaryFixedVariant": "#7c0024",

    "secondaryFixed": "#ffd9e2",
    "secondaryFixedDim": "#cbc2db",
    "onSecondaryFixed": "#ffb1c7",
    "onSecondaryFixedVariant": "#6c1d3d",

    "tertiaryFixed": "#ffddb4",
    "tertiaryFixedDim": "#e8c08e",
    "onTertiaryFixed": "#291800",
    "onTertiaryFixedVariant": "#503610",
}

export const LightTokensDefault = {
    ...fixedTokens,
    "primary": "#e40047", //"#bd023c",
    "onPrimary": "#ffffff",
    "primaryContainer": "#ffdadb",
    "onPrimaryContainer": "#40000e",

    "secondary": "#984060",
    "onSecondary": "#ffffff",
    "secondaryContainer": "#ffd9e2",
    "onSecondaryContainer": "#3e001d",

    "tertiary": "#775930",
    "onTertiary": "#ffffff",
    "tertiaryContainer": "#ffddb4",
    "onTertiaryContainer": "#291800",

    "surface": "#fffbff",
    "onSurface": "#201a1a",
    "surfaceVariant": "#f4ddde",
    "onSurfaceVariant": "#524344",
    
    "outline": "#857373",
    "outlineVariant": "#857373",
    "background": "#fffbff",
    "onBackground": "#201a1a",

    "surfaceDim": "#ddd8dd",
    "surfaceBright": "#fdf8fd",
    "surfaceContainerLowest": "#ffffff",
    "surfaceContainerLow": "#f7f2f7",
    "surfaceContainer": "#f2ecf1",
    "surfaceContainerHigh": "#ece7eb",
    "surfaceContainerHighest": "#e6e1e6",
    "surfaceTintColor": "#bd023c",

    // "inverseSurface": "#313033",
    // "inverseOnSurface": "#f4eff4",
    // "inversePrimary": "#cfbcff",
    // "inverseOnPrimary": "",
    "shadow": "#000000",
    "scrim": "#000000",

    "info": "#125db2",
    "onInfo": "#ffffff",
    "infoContainer": "#d6e3ff",
    "onInfoContainer": "#001b3d",

    "success": "#006d43",
    "onSuccess": "#ffffff",
    "successContainer": "#92f7bc",
    "onSuccessContainer": "#002111",

    "error": "#ba1a1a",
    "onError": "#ffffff",
    "errorContainer": "#ffdad6",
    "onErrorContainer": "#410002",

    "warning": "#ad3212",
    "onWarning": "#ffffff",
    "warningContainer": "#ffdad2",
    "onWarningContainer": "#3c0700",

   
};

export const DarkTokensDefault = {
    "primary": "#ffb2b8",
    "onPrimary": "#67001d",
    "primaryContainer": "#91002c",
    "onPrimaryContainer": "#ffdadb",

    "secondary": "#ffb1c7",
    "onSecondary": "#5e1132",
    "secondaryContainer": "#7b2949",
    "onSecondaryContainer": "#ffd9e2",

    "tertiary": "#e8c08e",
    "onTertiary": "#432c06",
    "tertiaryContainer": "#5d421b",
    "onTertiaryContainer": "#ffddb4",

    "surface": "#201a1a",
    "onSurface": "#ece0df",
    "surfaceVariant": "#524344",
    "onSurfaceVariant": "#d7c1c2",

    "outline": "#9f8c8d",
    "background": "#201a1a",
    "onBackground": "#ece0df",

    "surfaceDim": "#141316",
    "surfaceBright": "#3a383c",
    "surfaceContainerLowest": "#0f0e11",
    "surfaceContainerLow": "#1c1b1e",
    "surfaceContainer": "#201f22",
    "surfaceContainerHigh": "#2b292d",
    "surfaceContainerHighest": "#363438",
    "outlineVariant": "#49454e",
    "surfaceTintColor": "#bd023c",

    // "inverseSurface": "#e6e1e6",
    // "inverseOnSurface": "#313033",
    // "inversePrimary": "#6750a4",
    // "inverseOnPrimary": "",
    "shadow": "#000000",
    "scrim": "#000000",
    
    "error": "#ffb4ab",
    "onError": "#690005",
    "errorContainer": "#93000a",
    "onErrorContainer": "#ffdad6",

    "info": "#a9c7ff",
    "onInfo": "#003063",
    "infoContainer": "#00468c",
    "onInfoContainer": "#d6e3ff",

    "success": "#76daa1",
    "onSuccess": "#003920",
    "successContainer": "#005231",
    "onSuccessContainer": "#92f7bc",

    "warning": "#ffb4a2",
    "onWarning": "#621200",
    "warningContainer": "#8a1d00",
    "onWarningContainer": "#ffdad2",
    ...fixedTokens
};