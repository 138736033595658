import React, { useState, useContext, useEffect, useCallback } from 'react';  
import { Grid, Paper, Box } from '@mui/material';
import { useNavigate } from "react-router-dom";
import { AppContext, AuthContext } from 'core/context';
import Register from './Register';
import { useLocation } from 'react-router-dom';
import SignIn from './SignIn';
import { ROUTES } from 'core/utils/constants';
import { logger } from 'core/utils/logger';
// import CircleIcon from '@mui/icons-material/Circle';

export default function LoginPage() {
  logger.info('LoginPage mounting...');
  /* Hooks */
  const [toggleSignIn, setToggleSignIn] = useState(null);
  let navigate = useNavigate();
  const {isUserLoggedIn, resetContext: resetAuthContext} = useContext(AuthContext);
  const { resetContext: resetAppConext} = useContext(AppContext);
  const location = useLocation()
  
  useEffect(() => {
    logger.debug('LoginPage mounted');

    if(isUserLoggedIn){
      setTimeout(()=>navigate('/'), 100);
    }else{
      setToggleSignIn(location.pathname.indexOf(ROUTES.LOGIN) > -1 ? true : false);
      resetAuthContext();
      resetAppConext();
    }
  }, [navigate, location, resetAuthContext, isUserLoggedIn, resetAppConext]);

  const onSignupSuccess = useCallback(() => {
    logger.debug('LoginPage unmounted');
    setToggleSignIn(true); 
  },[]);
  

  return typeof toggleSignIn === 'boolean' ? (
    <Grid container component="main"
      sx={{ 
        minHeight: '100vh',
        backgroundColor: '#bc003b',
        width: '100%',
        backgroundImage: `url(assets/images/bg-pattern.png)`,
        backgroundPosition: 'center', 
        backgroundSize: 'cover',
      }}
      flexDirection={{xs: 'column', md:'row'}}
      justifyContent="center"
      alignItems="center"
      gap={1}
      rowGap={4}
      p={{xs: '2rem', md: '3rem'}}
      xs={12}
      item
    >
      {/* <CssBaseline /> */}
      <Grid container item xs={12}   elevation={1}
        sx={{
          // background: '#fff',
          baxShadow: '0px 4px 37px -21px rgba(0,0,0,0.75)',
          // minHeight: '50vh'
        }}
        justifyContent="center"
      >
        <Grid borderRadius={3} component={Paper} item xs={12} sm={8.5} md={6} lg={4} xl={3.5}  textAlign="center" sx={{p:4}} display="flex" flexDirection="column" justifyContent="space-between">
          <Box my={2}>
            <Box
              style={{ 
                width: '100px',
                display: 'block',
                textAlign: 'center',
                position: 'relative',
                margin: '0 auto 2rem',
              }}
            >
              <img
                style={{ 
                  width: '80%',
                }}
                alt="PG Manager Logo" src={`assets/images/logo-color.png`}
                className='login-logo'
              />
               PG Manager™
            </Box>
            {toggleSignIn ? <SignIn /> : <Register onSignupSuccess={onSignupSuccess} />}
          </Box>
          {/* <Stack direction="row" columnGap={2} justifyContent="center" alignItems="center" mt={2} flexWrap="wrap" rowGap={1}>
            <Link target="_blank" component={RouterLink} to={ROUTES.ABOUT_US} fontSize={14} color="secondary">About</Link>
            <CircleIcon sx={{fontSize: 4, color: 'secondary'}} />
            <Link target="_blank" component={RouterLink} to={ROUTES.PRIVACY_POLICY} fontSize={14} color="secondary">Privacy</Link>
            <CircleIcon sx={{fontSize: 4, color: 'secondary'}} />
            <Link target="_blank" component={RouterLink} to={ROUTES.TERMS_OF_USE} fontSize={14} color="secondary">Terms of use</Link>
            <CircleIcon sx={{fontSize: 4, color: 'secondary'}} />
            <Link target="_blank" component={RouterLink} to={ROUTES.CONTACT_US} fontSize={14} color="secondary">Contact us</Link>
          </Stack> */}
        </Grid>
      </Grid>
      <Grid container item xs={11} sm={11} md={5} lg={6} xl={7} justifyContent="center" display="flex"  >

        {/* <Typography variant='h4' color="surface">What is Lorem Ipsum What is Lorem Ipsum What is Lorem Ipsum?</Typography> */}
        {/* <Typography variant='caption' color="surface">s simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic</Typography> */}
        {/* <img alt="" src='assets/images/login-banner.png' /> */}
      </Grid>
    </Grid>
  ) : null;
}
