import React from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { Box, CssBaseline } from '@mui/material';
import pgmTheme from 'core/theme';
import { Outlet } from 'react-router-dom';
import { logger } from 'core/utils/logger';
// import Copyright from 'components/CopyRight';

const AppPublicLayout = ({ children }) => {
  logger.info('AppPublicLayout mounting...');

  return (
    <ThemeProvider theme={pgmTheme}>
      <CssBaseline />
      <Box>
        <Outlet />
      </Box>
      {/* <Copyright sx={{ p: 2, backgroundColor: '#ffffff'}} />   */}
    </ThemeProvider>
  );
}
 
export default React.memo(AppPublicLayout);
